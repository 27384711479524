import { CompleteBatchResponse } from "../Models/AppConfig/CompleteBatchResponse";
import Batch from "../Models/Batch";
import { BatchTabs } from "../Models/BatchTabs";
import { AppModes } from "../Models/Constants/AppModes";
import { NavBarOptions } from "../Models/Constants/NavBarOptions";
import { KinshipPanelConfig } from "../Models/Constants/PanelConfigs";
import Patient from "../Models/Patient";
import Specimen from "../Models/Specimen";
import TestType from "../Models/TestType";
import { AppModeConfig, BatchConfig } from "./AppModeConfig"; 

const completeBatch = async (batch: Batch, specimens: Specimen[], patients: Patient[]) => {
    
    console.log("Beginning complete batch for Kinship mode");

    var result: CompleteBatchResponse = {
        isSuccess: false,
        messages: []
    };

    // We previously had functionality here to send comments to Paternity API
    // The method still exists (SubmitSampleComments()) but wasn't being used
    // reimplement if necessary

    result.isSuccess = true;

    console.log("Successfully completed batch for Kinship mode");

    return result;
}

const config: BatchConfig = {
    fetchData: () => {},
    archiveData: () => {},
    completeBatch: completeBatch,
    defaultPanelConfig: KinshipPanelConfig,
    requiredFields: [],
    testTypes: [
        TestType.DATAANALYSIS,
        TestType.DATACHECK,
        TestType.DIRECTORREVIEW,
        TestType.FRAGMENTANALYSIS,
        TestType.PCR,
        TestType.PUNCHING,
        TestType.RESULTSDELIVERY
    ],
    batchTabs: [
        BatchTabs.VIEW,
        BatchTabs.CREATE,
        BatchTabs.ARCHIVED,
        BatchTabs.KINSHIPSEARCH
    ]
};

export const KinshipModeConfig: AppModeConfig = {
    NavBarOptions: [
        NavBarOptions.batches,
        NavBarOptions.testing
    ],
    BatchConfig: config,
    name: AppModes.KINSHIP
}