const awsConfig = {
    aws_project_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    federationTarget: "COGNITO_USER_POOLS",
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
}

export default awsConfig;
