import { useContext, useState } from "react";
import { TestComponentsWrapper, TestComponent, AttributeComponent } from "../../styles";
import { Typography, FormControl, Button } from "@mui/material";
import ReagentComponentProps from "../ReagentComponentProps";
import BatchAttribute from "../../../../Models/BatchAttribute";
import { AuthContext } from "../../../../Context/Auth/AuthContext";
import AuthContextType from "../../../../Models/AuthContextType";
import ReagentEntry from '../../../../Components/ReagentEntry';
import CustomReagentAttributes from '../../../../Models/CustomReagentAttributes';
import ReagentData from "../../../../Models/ReagentData";

const ApproverReagentTestComponent = ({reagents, onSubmit, type, name}: ReagentComponentProps) => {
    var authContext = useContext(AuthContext) as AuthContextType;

    const [reagentData, setReagentData] = useState<ReagentData[]>([]);

    const handleSubmit = () => {

        var reagentAttributes: CustomReagentAttributes = {
            reagents: reagentData
        };

        var attribute: BatchAttribute = {
            type: type,
            approver: authContext.user!.getUsername(),
            customAttributes: JSON.stringify(reagentAttributes),
            dateCreated: new Date()
        };

        onSubmit(attribute);
    };

    const handleReagentUpdate = (reagentData: ReagentData[]) => {
        setReagentData(reagentData);
    };

    return (
        <TestComponentsWrapper>
            <TestComponent>
                <Typography gutterBottom variant="h6" component="div">
                    {name}
                </Typography>
            </TestComponent>
            <TestComponent>
                <FormControl>
                    <Button onClick={handleSubmit} variant="contained">
                        SUBMIT
                    </Button>
                </FormControl>
            </TestComponent>
            <AttributeComponent>
                <ReagentEntry reagents={reagents} onUpdate={handleReagentUpdate} />
            </AttributeComponent>
        </TestComponentsWrapper>
    )
}

export default ApproverReagentTestComponent;