enum TestType {
    PUNCHING = "PUNCHING",
    PCR = "PCR",
    FRAGMENTANALYSIS = "FRAGMENT ANALYSIS",
    DATAANALYSIS = "DATA ANALYSIS",
    DATACHECK = "DATA CHECK",
    DIRECTORREVIEW = "DIRECTOR REVIEW",
    RESULTSDELIVERY = "RESULTS DELIVERY",
    EXTRACTION = "EXTRACTION"
};

export default TestType;