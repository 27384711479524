import React from "react";
import { NavBarOption } from "../NavBarOption";
import GridOnIcon from '@mui/icons-material/GridOn';
import BiotechIcon from '@mui/icons-material/Biotech';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

interface NavBarType {
    specimenReception: NavBarOption,
    batches: NavBarOption,
    testing: NavBarOption
}

export const NavBarOptions: NavBarType = {

    specimenReception: {
        name: "Specimen reception",
        redirect: "/specimenreception",
        icon: <QrCodeScannerIcon />
    },
    batches: {
        name: "Batches",
        redirect: "/batches",
        icon: <GridOnIcon />
    },
    testing: {
        name: "Testing",
        redirect: "/testing",
        icon: <BiotechIcon />
    }

};