import ArchivedBatch from "../../../../../../Models/ArchivedBatch";
import ArchivedSpecimen from "../../../../../../Models/ArchivedSpecimen";
import ArchivedPatient from "../../../../../../Models/ArchivedPatient";
import BatchReportData from '../../../../../../Models/BatchReportData';
import WellType from "../../../../../../Models/WellType";

export const GeneratePanelData = (batch: ArchivedBatch, specimens: ArchivedSpecimen[], patients: ArchivedPatient[]): BatchReportData[] => {
    var result: BatchReportData[] = [];

    batch.specimens.forEach(batchSpecimen => {
        let regex = new RegExp("LU[ABCDEFGHJKLMNPQRTUVWXYZ2346789]{6}");
        var specimen = null;
        var patient = null;

        if (!regex.test(batchSpecimen.specimenId)) {
            specimen = specimens.find(spec => batchSpecimen.specimenId === spec.specimenId);
        }

        if (regex.test(batchSpecimen.specimenId) && specimen === null) {
            patient = patients.find(patient => patient.sampleId === batchSpecimen.specimenId);
        }

        if (specimen === null && patient === null) {
            var message = `Specimen ID ${batchSpecimen.specimenId} is found in archived batch ${batch.name} but cannot be found in list of archived specimens`;
            alert(message); // Very spammy when things are wrong, just going to console.error this instead
            console.error(message);
            return [];
        }

        if (specimen) {
            result.push({
                wellNumber: batchSpecimen.wellNumber,
                wellType: WellType.STANDARD,
                specimen: specimen
            });
        } else if (patient) {
            result.push({
                wellNumber: batchSpecimen.wellNumber,
                wellType: WellType.STANDARD,
                patient: patient
            });
        }
    })

    return result;
}