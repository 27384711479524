import { TableBody, TableRow, TableCell } from "@mui/material"
import React from "react"
import { Table } from "react-bootstrap"
import Batch from "../../../../../../Models/Batch"
import { BatchTestDetails } from "../BatchTestDetails"
import { Heading } from "../PatientRow/styles"
import { ContentWrapper } from "./styles"

interface BatchDetailsProps {
    batch: Batch,
    specimenId: string
}

export const BatchDetails = ({ batch, specimenId }: BatchDetailsProps) => {

    var wellNumber = batch.specimens.find(specimen => specimen.specimenId == specimenId)?.wellNumber;

    return (
        <ContentWrapper>
            <h3>
                Batch details
            </h3>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Heading>Name</Heading>
                        </TableCell>
                        <TableCell>
                            {batch.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Heading>Patient sample well number</Heading>
                        </TableCell>
                        <TableCell>
                            {wellNumber}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Heading>Creation date</Heading>
                        </TableCell>
                        <TableCell>
                            {`${batch.dateCreated.toLocaleDateString('en-AU')} ${batch.dateCreated.toLocaleTimeString('en-AU')}`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Heading>Rack Barcode</Heading>
                        </TableCell>
                        <TableCell>
                            {batch.rackBarcode}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Heading>Plate Barcode</Heading>
                        </TableCell>
                        <TableCell>
                            {batch.plateBarcode}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            
            {batch.attributes.map((att, index) => <BatchTestDetails attribute={att} key={`batch-test-details-${index}`} />)}
        </ContentWrapper>
    )
}