import { FormControl, TextField } from '@mui/material';
import React, { SyntheticEvent, useState, KeyboardEvent, useEffect, useRef } from 'react';
import WellData from '../../Models/WellData';
import WellType from '../../Models/WellType';
import { BorderBox, FlexBox, SideBySide } from './styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { WellEntryData } from '../../Models/WellEntryData';

interface WellProps {
    index: number,
    data: WellData,
    focused: boolean,
    onFill:(barcode: string, wellNumber: string) => WellEntryData,
    onFocus:(index: number) => void,
    onRemove:(wellNumber: string) => void
}

const Well = ({index, data, focused, onFill, onFocus, onRemove}: WellProps) => {

    const [inputMode, setInputMode] = useState<Boolean>(data.type === WellType.STANDARD);
    const [wellEntryData, setWellEntryData] = useState<WellEntryData | null>(null);

    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (focused) inputRef.current?.focus();
    }, [focused]);

    const handleOnFocus = (event: SyntheticEvent) => {
        onFocus(index);
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            var htmlEvent = event as any;
            var barcode = htmlEvent.target.value;

            var resetField = false;

            // onFill returns whether specimen is valid and returns data for the well
            var wellEntryData = onFill(barcode, data.number);
            if (wellEntryData.valid) {
                setWellEntryData(wellEntryData);
                setInputMode(false);
            } else resetField = true;

            if (resetField) htmlEvent.target.value = "";
        }
    }

    const handleOnRemove = (event: SyntheticEvent) => {
        setWellEntryData(null);
        setInputMode(true);
        onRemove(data.number);
    }

    const displayModeContent = (): JSX.Element => {
        switch (data.type) {
            case WellType.STANDARD:
                if (wellEntryData) {
                    return (
                        <>
                            <div>{wellEntryData.name}</div>
                            <div>{wellEntryData.id.toUpperCase()} </div>
                        </>
                        );
                } 
                return <></>
            case WellType.LADDER:
                return <>LADDER</>
            case WellType.POSITIVECONTROL:
                return <>POSITIVE CONTROL</>
            case WellType.NEGATIVECONTROL:
                return <>NEGATIVE CONTROL</>
        }
    };
    
    const inputModeContent = (): JSX.Element => {
        return (
            <FormControl>
                <TextField 
                    id="standard-basic" 
                    label="" 
                    variant="standard" 
                    focused={focused} 
                    onFocus={handleOnFocus}
                    onKeyUp={handleKeyUp}
                    inputRef={inputRef}
                />
            </FormControl>
        );
    }
   
    var content = inputMode ? inputModeContent() : displayModeContent();

    return (
        <BorderBox focused={focused} >
            <>
                <SideBySide>
                    <FlexBox>
                        {data.number}
                    </FlexBox>
                    <FlexBox style={{marginLeft: 'auto'}} onClick={handleOnRemove}>
                        {(!inputMode && data.type === WellType.STANDARD) &&
                        <CancelIcon/>}
                    </FlexBox>
                </SideBySide>
                {content}
            </>
        </BorderBox>
    )
}

export default Well;