import Batch from "../../../../Models/Batch";
import PanelData from "../../../../Models/PanelData";
import WellData from "../../../../Models/WellData";
import WellType from "../../../../Models/WellType";
import PanelConfigType from "../../../../Models/PanelConfigType";
import BatchSpecimen from "../../../../Models/BatchSpecimen";
import Specimen from "../../../../Models/Specimen";
import ValidateBatchResponse from '../../../../Models/ValidateBatchResponse';
import UpdateBatchProgressAction from '../../../../Models/Constants/UpdateBatchProgressAction';
import ControlWellConfig from "../../../../Models/ControlWellConfig";
import { AppModeConfig } from "../../../../AppModes/AppModeConfig";
import { CreateBatchScreenFields } from "../../../../Models/AppConfig/CreateBatchScreenFields";


export const InitializeNewPanel = ({height, width, ladderStartingWell, ladderFrequency, controlWells}: PanelConfigType): PanelData => {
    const asciiCapitalA = 65;

    var wells: WellData[] = [];

    // Initialize panel with wells A1, B1, C1, D1, ... H1, A2, B2... according to rows (letters) x columns (numbers) 
    for (var i = 1; i <= height; i++) {
        for (var j = 0; j < width; j++) {

            let well: WellData = {
                number: `${String.fromCharCode(asciiCapitalA + j)}${i}`,
                type: WellType.STANDARD
            }

            wells.push(well);
        }
    }

    var panelData = {
        height,
        width,
        wells
    };

    if (ladderStartingWell && ladderFrequency) panelData = SetupLadderWells(panelData, ladderStartingWell, ladderFrequency);
    if (controlWells) panelData = SetupControlWells(panelData, controlWells);

    return panelData;
}

const FormatDateString = (date: Date) => {
    var year = date.toLocaleString("default", { year: "numeric"});
    var month = date.toLocaleString("default", { month: "2-digit"});
    var day = date.toLocaleString("default", { day: "2-digit"});

    return `${year}-${month}-${day}`;
}

const GetStartOfDayEpoch = (dateString: string) => {
    return new Date(dateString).getTime();
}

const GenerateDefaultBatchName = (batches: Batch[]) => {

    var todayDateString = FormatDateString(new Date());
    var todayEpoch = GetStartOfDayEpoch(todayDateString);

    var numberOfBatchesCreatedToday = batches.filter(batch => {
        var dateString = FormatDateString(new Date(batch.dateCreated));
        var dateEpoch = GetStartOfDayEpoch(dateString);
        return todayEpoch <= dateEpoch;
    }).length;

    return `${todayDateString}-P${numberOfBatchesCreatedToday + 1}`;
}

const SetupLadderWells = (panelData: PanelData, startingWellNumber: string, frequency: number): PanelData => {

    var startingWell = panelData.wells.find(well => well.number === startingWellNumber);

    if (!startingWell) {
        alert(`Could not setup Ladder wells as starting well number ${startingWellNumber} was not found`);
        return panelData;
    }

    var cursorIndex = panelData.wells.indexOf(startingWell);
    
    do {
        panelData.wells[cursorIndex].type = WellType.LADDER;
        cursorIndex += frequency;
    } while (cursorIndex < panelData.wells.length)

    return panelData;
}

const SetupControlWells = (panelData: PanelData, controlWells: ControlWellConfig) => {
    var wellIndex;
    
    if (controlWells.positiveControl) {
        wellIndex = panelData.wells.findIndex(well => well.number === controlWells.positiveControl);
        if (wellIndex !== -1) panelData.wells[wellIndex].type = WellType.POSITIVECONTROL;
    }

    if (controlWells.negativeControl) {
        wellIndex = panelData.wells.findIndex(well => well.number === controlWells.negativeControl);
        if (wellIndex !== -1) panelData.wells[wellIndex].type = WellType.NEGATIVECONTROL;
    }

    return panelData;
};

export const InitializeNewBatch = (username: string, batchState: Batch[]): Batch => {
    var batch: Batch = {
        name: GenerateDefaultBatchName(batchState),
        rackBarcode: "",
        plateBarcode: "",
        tube2DBarcodesFilename: "",
        dnaConcentrationsFilename: "",
        createdBy: username,
        dateCreated: new Date(),
        specimens: [],
        attributes: []
    }

    return batch;
}

export const AddSpecimenToBatch = (batch: Batch, specimenId: string, wellNumber: string): Batch => {
    var batchSpecimen : BatchSpecimen = {
        specimenId,
        wellNumber
    };

    batch.specimens.push(batchSpecimen);

    return batch;
};

export const RemoveSpecimenFromBatchByWellNumber = (batch: Batch, wellNumber: string): Batch => {
    var specimen = batch.specimens.find(x => x.wellNumber === wellNumber);

    if (!specimen) return batch;

    var index = batch.specimens.indexOf(specimen);

    batch.specimens.splice(index, 1);

    return batch;
}

export const UpdateBatchName = (batch: Batch, name: string): Batch => {
    return {
        ...batch,
        name: name
    };
};

export const UpdateBatchRackBarcode = (batch: Batch, rackBarcode: string): Batch => {
    return {
        ...batch,
        rackBarcode: rackBarcode
    };
};

export const UpdateBatchPlateBarcode = (batch: Batch, plateBarcode: string): Batch => {
    return {
        ...batch,
        plateBarcode: plateBarcode
    };
};

export const UpdateBatchTube2DBarcodesFilename = (batch: Batch, tube2DBarcodesFilename: string): Batch => {
    return {
        ...batch,
        tube2DBarcodesFilename: tube2DBarcodesFilename
    };
};

export const GroupSpecimensByCaseId = (specimens: Specimen[]): Map<string, Specimen[]> => {
    var dict = new Map<string, Specimen[]>();

    specimens.forEach(specimen => {
        var caseId = specimen.caseId;

        var indexExists = dict.has(caseId);

        if (!indexExists) {
            var list: Specimen[] = [specimen];
            dict.set(caseId, list);
            return;
        } 

        dict.get(caseId)?.push(specimen);
    });

    return dict;
}

export const UpdateBatchProgress = (
    action: UpdateBatchProgressAction,
    currentBatch: Batch,
    currentBatchProgress: Specimen[],
    specimen: Specimen, // | null,
    specimensGroupedByCaseId: Map<string, Specimen[]>
) => {

    var result = currentBatchProgress;

    // Get other specimens in the same case as specimen being added
    var caseSpecimens = specimensGroupedByCaseId.get(specimen == null ? "" : specimen.caseId);

    if (!caseSpecimens) return result;

    var batchSpecimenIds = currentBatch.specimens.map(x => x.specimenId);

    switch (action) {
        case UpdateBatchProgressAction.ADD:
            // Check if the other specimens (not the one being added) are already in batch
            var specimensNotInBatch = caseSpecimens.filter(x => !batchSpecimenIds.includes(x.specimenId));

            // If they aren't in the batch, and they aren't in Batch Progress, add to Batch Progress
            result = specimensNotInBatch.filter(x => !currentBatchProgress.includes(x));

            // Todo: Check if they're already in batch progress? Don't want any duplicates
            break;
        case UpdateBatchProgressAction.REMOVE:
            // Exclude the specimen we are removing
            if (specimen !== null) {
            var caseSpecimenExcluded = caseSpecimens.filter(spec => spec.specimenId !== specimen.specimenId);

            // Check if any of the case specimens (excluded) are in the batch
            var caseSpecimenExcludedInBatch = false;
            caseSpecimenExcluded.forEach(caseSpec => {
                if (batchSpecimenIds.includes(caseSpec.specimenId)) caseSpecimenExcludedInBatch = true;
            })

            if (caseSpecimenExcludedInBatch) {
                // If so, add the specimen being removed to Batch Progress
                result.push(specimen);
            } else {
                // If none, remove all case specimens from progress
                caseSpecimens.forEach(caseSpecimen => {
                    var index = result.indexOf(caseSpecimen);
                    if (index !== -1) {
                        result.splice(index, 1);
                    }
                })
            }
        }
            break;
    }

    return result;
}

export const ValidateBatch = (
    currentBatch: Batch,
    historicalBatchState: Batch[],
    batchProgress: Specimen[],
    appModeConfig: AppModeConfig
): ValidateBatchResponse => {
    
    var result: ValidateBatchResponse = {
        isValid: true,
        displayWarning: false,
        validationErrorMessages: []
    }

    // Check if name is empty
    if (currentBatch.name.length === 0) {
        result.isValid = false;
        result.validationErrorMessages.push("Batch name cannot be empty");
    }

    // Check if name already exists in historical batches
    if (historicalBatchState.map(batch => batch.name).includes(currentBatch.name)) {
        result.isValid = false;
        result.validationErrorMessages.push("Batch name already exists! Names must be unique");
    }

    // Check if there's anything in Batch Progress
    if (batchProgress.length > 0) {
        result.displayWarning = true;
        result.validationErrorMessages.push("There are still items in batch progress! Are you sure you want to continue?");
    }

    // Check if there's at least one specimen
    if (currentBatch.specimens.length == 0) {
        result.isValid = false;
        result.validationErrorMessages.push("You must have at least one specimen to submit a batch");
    }

    // Validate if the required fields in the app mode config have been filled
    if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.RACK)) {
        if (!currentBatch.rackBarcode) {
            result.isValid = false;
            result.validationErrorMessages.push("You must have a rack barcode for batches with mode " + appModeConfig.name);
        }
    }

    if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.PLATE)) {
        if (!currentBatch.plateBarcode) {
            result.isValid = false;
            result.validationErrorMessages.push("You must have a plate barcode for batches with mode " + appModeConfig.name);
        }
    }

    if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.TUBE2DBARCODE)) {
        if (!currentBatch.tube2DBarcodesFilename) {
            result.isValid = false;
            result.validationErrorMessages.push("You must have a 2D tube barcode for batches with mode " + appModeConfig.name);
        }
    }

    return result;
}