import CustomReagentAttributes from '../Models/CustomReagentAttributes';
import BatchAttribute from '../Models/BatchAttribute';
import React from 'react';
import { Chip } from '@mui/material';
import styled from 'styled-components';

export const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

export const ChipWrapper = styled.div`
    display: flex;
    width: 100%;

    padding: 0.5rem 0;
`;

const GenerateReagentTestTypeDisplay = (attribute: BatchAttribute) => {
    var reagentAttribute: CustomReagentAttributes = JSON.parse(attribute.customAttributes ?? "");

    if (!reagentAttribute || !reagentAttribute.reagents) return <></>;

    return (
        <ListWrapper>
            <div>
                Reagents
            </div>
            {reagentAttribute.reagents.map((reagent, index) => 
                <ChipWrapper key={`value-chip-wrapper-${index}`}>
                    <Chip 
                        key={`value-chip-${index}`}
                        label={`${reagent.name}-${reagent.lotNumber}-${reagent.expiry}`}
                    />
                </ChipWrapper>
            )}
        </ListWrapper>
    );
}

export default GenerateReagentTestTypeDisplay;