import styled from "styled-components";

export const GridWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-around;
`;

export const WellColumn = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: space-around;
`;