import Batch from "../../Models/Batch";
import Patient from "../../Models/Patient";

export const DetermineOutstandingPatients = (patients: Patient[], batches: Batch[]): Patient[] => {

    // First gather a list of all patients that are batched
    var specimenIdsInBatches: string[] = [];

    batches.forEach(batch => {

        var specimenIdsInBatch = batch.specimens.map(specimen => specimen.specimenId);

        specimenIdsInBatch.forEach(batchSpecimenId => {
            var index = specimenIdsInBatches.findIndex(specimenId => specimenId === batchSpecimenId);
            if (index === -1) specimenIdsInBatches.push(batchSpecimenId);
        });
    });

    // Then create a list of specimens that aren't batched from the first list
    var patientsNotInBatches = patients.filter(patient => !specimenIdsInBatches.includes(patient.sampleId));

    // Then create a list of specimens that that have not been reported as error
    var patientsNotReportedInError = patientsNotInBatches.filter(patient => patient.sampleErrorNote === "");

    return patientsNotReportedInError;
}

export const AreAnyOutstandingPatientsCritical = (patients: Patient[]): boolean => {
    var anyCriticalSpecimens = patients.findIndex(patient => IsOutstandingPatientCritical(patient));
    return anyCriticalSpecimens !== -1;
}

export const IsOutstandingPatientCritical = (patient: Patient) => {

    // An outstanding specimen is considered critical if it is over 3 days old
    var criticalDays = 3;

    var criticalCreationDateTime = new Date();
    criticalCreationDateTime.setDate(criticalCreationDateTime.getDate() - criticalDays);
    
    // Need to parse patient created date to Date type
    var patientCreatedDate = new Date(patient.dateCreated);

    return patientCreatedDate <= criticalCreationDateTime;
}
