import styled from "styled-components";

interface BorderBoxProps {
    focused: boolean
}

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 3px;
    border-radius: 15px;
    border-color: ${(props: BorderBoxProps) => props.focused ? 'red': 'black'};

    width: 8rem;
    height: 6rem;

    padding: 0.5rem;

    font-size: 12px;
    font-weight: bold;
`;

export const SideBySide = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexBox = styled.div`
    display: flex;
`;