import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import { StatusCellWrapper, StatusIconWrapper, StatusTextWrapper, BoxWrapper, CollapseCell } from './styles';
import Patient from '../../../../../../Models/Patient';
import { Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PatientDetails } from '../PatientDetails';
import { BatchDetails } from '../BatchDetails';
import Batch from '../../../../../../Models/Batch';

interface PatientRowProps {
    status: "outstanding" | "batched" | "archived", 
    patient: Patient,
    batch: Batch | undefined,
    onClick: (batchName: string, isArchived: boolean) => void,
    onPatientVCGSToggle: (patient: Patient) => void,
    onPatientGeneByGeneToggle: (patient: Patient) => void,
    onSavePatientToggles: (patient: Patient) => void,
    onUnarchivePatient: (sampleId: string) => void
}

interface StatusCellProps {
    status: "active" | "outstanding" | "batched" | "archived"
}

// Todo: Move to theme
const activeColor = "#4caf50";
const archivedColor = "#808080";

const StatusCell = (props: StatusCellProps) => {
    var isActive = props.status !== "archived";
    return (
    <TableCell 
        component="th" 
        scope="row" 
        width="10%"
        style={{color: isActive ? activeColor : archivedColor}} >
            <StatusCellWrapper>
                <StatusIconWrapper>
                {isActive ? <CheckIcon color="inherit" /> : <ArchiveIcon color="inherit" />}
                </StatusIconWrapper>
                <StatusTextWrapper>
                    {props.status.toUpperCase()}
                </StatusTextWrapper>
            </StatusCellWrapper>
    </TableCell>);
}

export const PatientRow = ({status, patient, batch, onClick, onPatientVCGSToggle, onPatientGeneByGeneToggle, onSavePatientToggles, onUnarchivePatient} : PatientRowProps) => {

    const [rowOpen, setRowOpen] = useState<boolean>(false);

        const toggleRowOpen = () => {
            setRowOpen(!rowOpen);
        };

        const numberOfColumns = 4;

        return (
            <React.Fragment key={`search-patient-row-${patient.sampleId}`}>
                <TableRow
                    key={`patient-row-${patient.sampleId}`}
                    sx={{ 
                            '&:last-child td, &:last-child th, ': { border: 0 }
                        }}
                    hover
                >
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={toggleRowOpen}
                            >
                                {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <StatusCell status={status} />
                        <TableCell>{patient.sampleId}</TableCell>
                        <TableCell>{`${patient.firstName.toUpperCase()} ${patient.lastName.toUpperCase()}`}</TableCell>
                        <TableCell>{batch ? batch.name : ""}</TableCell>
                </TableRow>
                <TableRow>
                    <CollapseCell colSpan={numberOfColumns}>
                        <Collapse in={rowOpen} timeout="auto" unmountOnExit>
                            <BoxWrapper>
                                <PatientDetails 
                                    patient={patient} 
                                    status={status}
                                    onPatientVCGSToggle={onPatientVCGSToggle}
                                    onPatientGeneByGeneToggle={onPatientGeneByGeneToggle}
                                    onSavePatientToggles={onSavePatientToggles}
                                    onUnarchivePatient={onUnarchivePatient}
                                />
                                {batch &&
                                <BatchDetails
                                    batch={batch!}
                                    specimenId={patient.sampleId}
                                />}
                            </BoxWrapper>
                        </Collapse>
                    </CollapseCell>
                </TableRow>
            </React.Fragment>
        );
}