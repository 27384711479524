import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Patient from '../Models/Patient';

export interface PatientState {
    patients: Patient[]
}

const initialState: PatientState = {
    patients: []
}

export const patientSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setPatients: (state, action: PayloadAction<Patient[]>) => {
      state.patients = action.payload
    },
    updatePatient: (state, action: PayloadAction<Patient>) => {
      var index = state.patients.findIndex(patient => patient.sampleId === action.payload.sampleId);
      if (index !== -1) state.patients.splice(index, 1, action.payload);
    },
    removePatients: (state, action: PayloadAction<Patient[]>) => {
      var resultState = [...state.patients];

      action.payload.forEach(patient => {
        var index = resultState.indexOf(patient);
        if (index !== -1) resultState.splice(index, 1);
      })

      state.patients = resultState;
    },
  },
  // removed removePatients
})

// Action creators are generated for each case reducer function
export const { setPatients, removePatients, updatePatient } = patientSlice.actions

export default patientSlice.reducer