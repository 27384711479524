import { useState } from "react";
import { ComponentWrapper, Row, ThirdWrapper } from "./styles";
import ReagentData from '../../Models/ReagentData';
import ReagentEntryRow from '../ReagentEntryRow';

interface ReagentEntryProps {
    reagents: string[],
    onUpdate:(reagentData: ReagentData[]) => void
}

const ReagentEntry = ({reagents, onUpdate}: ReagentEntryProps) => {
    const [reagentData, setReagentData] = useState<ReagentData[]>([]);

    const onReagentUpdate = (data: ReagentData) => {

        //Upsert into reagent data
        var index = reagentData.findIndex(reagentData => reagentData.name === data.name);

        var updatedReagentData = reagentData;

        if (index === -1) {
            updatedReagentData = [...reagentData, data];
        } else {
            updatedReagentData.splice(index, 1, data);
        }

        setReagentData(updatedReagentData);
        onUpdate(updatedReagentData);
    }

    return (
        <ComponentWrapper>
            <Row>
                <ThirdWrapper>
                    Reagent
                </ThirdWrapper>
                <ThirdWrapper>
                    Lot number
                </ThirdWrapper>
                <ThirdWrapper>
                    Expiry
                </ThirdWrapper>
            </Row>
            {reagents.map((reagent, index) => <ReagentEntryRow key={`reagent-row-${index}`} reagent={reagent} onUpdate={onReagentUpdate} />)}
        </ComponentWrapper>
    )
}

export default ReagentEntry;
