import React, { useEffect, useRef, useState } from "react";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArchivedBatch from "../../../../../../Models/ArchivedBatch";
import ArchivedSpecimen from "../../../../../../Models/ArchivedSpecimen";
import ArchivedPatient from "../../../../../../Models/ArchivedPatient";
import { ContentWrapper } from './styles';
import ExpandedBatchContent from '../ExpandedBatchContent';
import { AppModeConfig } from "../../../../../../AppModes/AppModeConfig";

interface ExpandableBatchRowProps {
    batch: ArchivedBatch,
    specimens: ArchivedSpecimen[],
    patients: ArchivedPatient[],
    appModeConfig: AppModeConfig,
    isSelected?: boolean
}

const ExpandableBatchRow = ({batch, specimens, patients, isSelected = false, appModeConfig} : ExpandableBatchRowProps) => {
    const [open, setOpen] = useState(isSelected);

    var elementRef = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (isSelected) {
            elementRef.current?.scrollIntoView({
                behavior: "smooth"
            })
        }
    }, [isSelected]);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} ref={elementRef}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography variant="h6" gutterBottom component="div">
                        {batch.name}
                    </Typography>
                </TableCell>
                <TableCell>
                    <ContentWrapper>
                        {batch.createdBy}
                    </ContentWrapper>
                </TableCell>
                <TableCell>
                    <ContentWrapper>
                        {batch.dateCreated.toString()}
                    </ContentWrapper>
                </TableCell>
                <TableCell>
                    <ContentWrapper>
                        {batch.archivedBy}
                    </ContentWrapper>
                </TableCell>
                <TableCell>
                    <ContentWrapper>
                        {batch.archivedAt}
                    </ContentWrapper>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ExpandedBatchContent 
                            batch={batch} 
                            specimens={specimens} 
                            patients={patients}
                            appModeConfig={appModeConfig}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ExpandableBatchRow;