import { TableContainer } from '@mui/material';
import React from 'react';
import Batch from '../Batch';
import GenerateTestTableRow from '../../Utils/GenerateTestTableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { AppModeConfig } from '../../AppModes/AppModeConfig';

interface TestTableProps {
    batch?: Batch,
    appModeConfig: AppModeConfig
}

const TestTable = ({ batch, appModeConfig }: TestTableProps) => {

    if (!batch) return <></>;
    var batchAttributes = batch.attributes;

    var rows: JSX.Element[] = [];

    // Only generate rows for each type of test that should exist for the app mode
    appModeConfig.BatchConfig.testTypes.forEach(test => {
        var attribute = batchAttributes.find(x => x.type === test);
        rows.push(GenerateTestTableRow(test, attribute))
    });

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TestTable;