const GraphqlExceptionLogging = (error: any, errorMessage: String) =>  {
    if (error instanceof Error) 
        console.error(errorMessage + error.message)
    else if (error.errors?.length > 0)
        error.errors.forEach((error: Error) => {
            console.error(errorMessage + error.message)
        })
    else
        console.error(errorMessage + String(error));
}

export default GraphqlExceptionLogging;