import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Specimen from '../../../../../../Models/Specimen';
import ArchivedSpecimen from '../../../../../../Models/ArchivedSpecimen';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import { TableWrapper, StatusCellWrapper, StatusIconWrapper, StatusTextWrapper } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

interface SearchSpecimenTableProps {
    activeSpecimens: Specimen[],
    archivedSpecimens: ArchivedSpecimen[],
    onNavigateToBatch: (batchName: string, isArchived: boolean) => void
}

// Todo: Move to theme
const activeColor = "#4caf50";
const archivedColor = "#808080";

interface StatusCellProps {
    status: "active" | "batched" | "archived"
}

const StatusCell = (props: StatusCellProps) => {
    var isActive = props.status !== "archived";
    return (
    <TableCell 
        component="th" 
        scope="row" 
        width="10%"
        style={{color: isActive ? activeColor : archivedColor}} >
            <StatusCellWrapper>
                <StatusIconWrapper>
                {isActive ? <CheckIcon color="inherit" /> : <ArchiveIcon color="inherit" />}
                </StatusIconWrapper>
                <StatusTextWrapper>
                    {props.status.toUpperCase()}
                </StatusTextWrapper>
            </StatusCellWrapper>
            
    </TableCell>);
    }

const GenerateSpecimenRow = (
    status: "active" | "batched" | "archived", 
    id: string,
    caseId: string,
    name: string,
    dob: string,
    batch: string,
    onClick: (batchName: string, isArchived: boolean) => void) => {

        return (
            <TableRow
                key={`specimen-row-${id}`}
                sx={{ 
                        '&:last-child td, &:last-child th, ': { border: 0 }
                    }}
                hover
                onClick={() => onClick(batch, status === "archived")}
            >
                <StatusCell status={status} />
                <TableCell>{id}</TableCell>
                <TableCell>{caseId}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{dob}</TableCell>
                <TableCell>{batch}</TableCell>
            </TableRow>
        );
}

const SearchSpecimenTable = ({ activeSpecimens, archivedSpecimens, onNavigateToBatch }: SearchSpecimenTableProps) => {
    const batchState = useSelector((state: RootState) => state.batches.batches);

    const TableHeaders = ["Status", "ID", "Case ID", "Name", "Date of Birth", "Batch"];

    const rows: JSX.Element[] =  [];
    
    activeSpecimens.forEach(specimen => {
        let status: "active" | "batched" = "active";
        let batchName = "";

        // Check state for batch
        var batch = batchState.find(batch => batch.specimens.map(x => x.specimenId).includes(specimen.specimenId));

        if (batch) {
            status = "batched";
            batchName = batch.name;
        }

        rows.push(GenerateSpecimenRow(
            status,
            specimen.specimenId,
            specimen.caseId,
            `${specimen.firstName.toUpperCase()} ${specimen.lastName.toUpperCase()}`,
            specimen.dateOfBirth.toLocaleString(),
            batchName,
            onNavigateToBatch
            )
        )
    });

    archivedSpecimens.forEach(archivedSpecimen => {
        rows.push(GenerateSpecimenRow(
            "archived",
            archivedSpecimen.specimenId,
            archivedSpecimen.caseId,
            `${archivedSpecimen.firstName.toUpperCase()} ${archivedSpecimen.lastName.toUpperCase()}`,
            archivedSpecimen.dateOfBirth.toLocaleString(),
            archivedSpecimen.batchName,
            onNavigateToBatch
            )
        )
    });

    return (
        <TableWrapper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        {TableHeaders.map((header, index) => <TableCell key={`header-${index}`}>{header}</TableCell>)}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableWrapper>
        );
}

export default SearchSpecimenTable;