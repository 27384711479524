export const upsertBatches = `
mutation UpsertBatches($request: UpsertBatchRequest) {
    upsertBatches(request: $request) {
      isSuccess
    }
  }
`

export const upsertSpecimens = `
mutation UpsertSpecimens($request: UpsertSpecimenRequest) {
  upsertSpecimens(request: $request) {
    isSuccess
  }
}
`

export const archiveBatch = `
mutation ArchiveBatch($request: ArchiveBatchRequest) {
    archiveBatch(request: $request) {
      isSuccess
      archivedAt
    }
  }
`

export const archiveSpecimens  = `
mutation ArchiveSpecimens($request: ArchiveSpecimensRequest) {
    archiveSpecimens(request: $request) {
      isSuccess
      archivedAt
    }
  }
`

export const archivePatients  = `
mutation ArchivePatients($request: ArchivePatientsRequest) {
    archivePatients(request: $request) {
      isSuccess
      archivedAt
    }
  }
`

export const unarchivePatient  = `
mutation UnarchivePatient($request: UnarchivePatientRequest) {
    unarchivePatient(request: $request) {
      isSuccess
    }
  }
`

export const updateCommentBySpecimenId  = `
mutation UpdateCommentBySpecimenId($request: UpdateCommentBySpecimenIdInput) {
    updateCommentBySpecimenId(request: $request) {
      success
      errorMessage
    }
  }
`

export const sendManifestToPartners = `
mutation SendManifestToPartners($request: SendManifestToPartnersRequest) {
    sendManifestToPartners(request: $request) {
      isSuccess
    }
  }
`

export const upsertPatient = `
mutation upsertPatient($request: UpsertPatientRequest) {
  upsertPatient(request: $request) {
    isSuccess
  }
}
`

export const specimenReceived = `
mutation specimenReceived($request: SpecimenReceivedRequest) {
  specimenReceived(request: $request) {
    success
  }
}
`

export const specimenDnaExtracted = `
mutation specimenDnaExtracted($request: SpecimenDnaExtractedRequest) {
  specimenDnaExtracted(request: $request) {
    success
  }
}
`

export const specimenError = `
mutation specimenError($request: SpecimenErrorRequest) {
  specimenError(request: $request) {
    success
  }
}
`

export const upsertTube2DBarcodes = `
mutation UpsertTube2DBarcodes($request: UpsertTube2DBarcodesRequest) {
    upsertTube2DBarcodes(request: $request) {
      isSuccess
    }
  }
`

export const upsertDNAConcentrations = `
mutation UpsertDNAConcentrations($request: UpsertDNAConcentrationsRequest) {
    upsertDNAConcentrations(request: $request) {
      isSuccess
    }
  }
`
