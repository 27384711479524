import Batch from "../../../../Models/Batch";
import BatchReportData from "../../../../Models/BatchReportData";
import PanelConfigType from "../../../../Models/PanelConfigType";
import WellType from "../../../../Models/WellType";
import { ABIDataConstants } from '../../../../Models/Constants/ABIDataConstants'
import ABIDataPlateInformation from "../../../../Models/ABIDataPlateInformation";
import Specimen from "../../../../Models/Specimen";
import { UpdateCommentBySpecimenId } from "../../../../Graphql/Api";
import DNAConcentrations from '../../../../Models/DNAConcentrations';
import BatchDNAConcentrations from "../../../../Models/BatchDNAConcentrations";
import DNADilution from "../../../../Models/DNADilution";
import { SpectraHelper } from './spectra-helper';

export const MapPanelConfigToRows = (config: PanelConfigType): BatchReportData[] => {
    var result: BatchReportData[] = [];

    // Map ladder wells to Batch Report Row
    if (config.ladderStartingWell && config.ladderFrequency) {

        var fakePanel = InitializeFakePanel(config);

        var indexCursor = fakePanel.findIndex(x => x === config.ladderStartingWell);

        if (indexCursor !== -1) {
            do {
                result.push({
                    wellNumber: fakePanel[indexCursor],
                    wellType: WellType.LADDER
                });

                indexCursor += config.ladderFrequency
            } while (indexCursor < fakePanel.length)
        }
    }

    // Map control wells to Batch Report Row
    if (config.controlWells?.positiveControl) {
        result.push({
            wellNumber: config.controlWells.positiveControl,
            wellType: WellType.POSITIVECONTROL
        })
    }

    if (config.controlWells?.negativeControl) {
        result.push({
            wellNumber: config.controlWells.negativeControl,
            wellType: WellType.NEGATIVECONTROL
        })
    }

    return result;
}

const InitializeFakePanel = (config: PanelConfigType): string[] => {
    const asciiCapitalA = 65;

    var result: string[] = [];

        for (var i = 0; i < config.height; i++) {
            for (var j = 0; j < config.width; j++) {
                result.push(`${String.fromCharCode(asciiCapitalA + j)}${i}`);
            } 
        }

    return result;
};

export const GenerateABIDataFile = async (defaults: ABIDataConstants, batch: Batch, batchReportData: BatchReportData[]) => {

    // WARNING!
    // Formatting might look a little off but DO NOT ADJUST IT
    // I've placed the multiline string on multiple lines so it's easier to read
    // However I can't have indentation or it'll add it to the document :(

    var spacer = `\r\n`;

    var header = `3500 Plate Layout File Version 1.0\r\n`;

    var plateInformationHeader = `\
Plate Name,\
Application Type,\
Capillary Length (cm),\
Polymer,\
Number of Wells,\
Owner Name,\
Barcode Number,\
Comments\r\n`;
    
    var plateInformationObject: ABIDataPlateInformation = {...defaults.plateInformation};
    plateInformationObject.plateName = batch.name;

    var plateInformation = `\
${plateInformationObject.plateName},\
${plateInformationObject.applicationType},\
${plateInformationObject.capillaryLength},\
${plateInformationObject.polymer},\
${plateInformationObject.numberOfWells},\
${plateInformationObject.ownerName},\
${plateInformationObject.barcodeNumber},\
${plateInformationObject.plateComments}\r\n`;

    var sampleInformationHeader = `\
Well,\
Sample Name,\
Assay,\
File Name Convention,\
Results Group,\
Sample Type,\
User Defined Field 1,\
User Defined Field 2,\
User Defined Field 3,\
User Defined Field 4,\
User Defined Field 5,\
Comments\r\n`;

    var sampleInformation = "";

    batchReportData.forEach(reportData => {

        let sampleInformationObject = {...defaults.sampleInformation};

        let well = reportData.wellNumber;
        // The well must be formatted so the number is always two digits
        if (well.length === 2) well = `${well[0]}0${well[1]}`;
        
        sampleInformationObject.well = well;

        switch(reportData.wellType) {
            case WellType.STANDARD:
                let specimen = reportData.specimen;

                if (!specimen) {
                    alert("No specimen found for STANDARD type well " + reportData.wellNumber);
                    return;
                }

                sampleInformationObject.sampleName = `${specimen.firstName} ${specimen.lastName}`;
                sampleInformationObject.sampleType = "Sample";
                sampleInformationObject.sampleComments = `${specimen.comment}`;

                // Case ID
                sampleInformationObject.userField1 = `${specimen.caseId}`;

                // Relationship (F, M or C)
                sampleInformationObject.userField2 = `${ExtractRelationshipFromBarcode(specimen.barcode)}`

                // Specimen ID
                sampleInformationObject.userField3 = `${specimen.specimenId}`;

                break;
            case WellType.LADDER:
                sampleInformationObject.sampleType = "Allelic Ladder";
                break;
            case WellType.POSITIVECONTROL:
                sampleInformationObject.sampleType = "Positive Control";
                break;
            case WellType.NEGATIVECONTROL:
                sampleInformationObject.sampleType = "Negative Control";
                break;
        }

        sampleInformation += `\
${sampleInformationObject.well},\
${sampleInformationObject.sampleName},\
${sampleInformationObject.assay},\
${sampleInformationObject.fileNameConvention},\
${sampleInformationObject.resultsGroup},\
${sampleInformationObject.sampleType},\
${sampleInformationObject.userField1},\
${sampleInformationObject.userField2},\
${sampleInformationObject.userField3},\
${sampleInformationObject.userField4},\
${sampleInformationObject.userField5},\
${sampleInformationObject.sampleComments}\r\n`;
    });

    var fileContents = `\
${header}\
${spacer}\
${plateInformationHeader}\
${plateInformation}\
${spacer}\
${sampleInformationHeader}\
${sampleInformation}`;

    // Workaround to download file
    var downloadBlob = new Blob([fileContents], { type: "text/csv" });
    var url = URL.createObjectURL(downloadBlob);
    var link = document.createElement("a");
    link.download = `${batch.name}-ABI-3500XL-data.csv`;
    link.href = url;
    link.click();
}

export const GenerateDilutionCSVFile = async (batch: Batch, batchDNADilutionData: DNADilution[]) => {
    var fileContents = '';

    batchDNADilutionData.forEach(dnaDilutionData => {
        fileContents += `\
${dnaDilutionData.sampleId},\
${dnaDilutionData.concentration}\r\n`;
    });

    var downloadBlob = new Blob([fileContents], { type: "text/csv" });
    var url = URL.createObjectURL(downloadBlob);
    var link = document.createElement("a");
    link.download = `${batch.plateBarcode}_${batch.rackBarcode}_dilution.csv`;
    link.href = url;
    link.click();
}

export const ExtractRelationshipFromBarcode = (barcode: string) => {
    // barcode is made up of <specimen ID>-<relationship>
    var split = barcode.split('-');
    var relationship = split.reverse()[0];

    switch (relationship) {
        case "AF": return "F"
        case "M": return "M"
        case "AM": return "M"
        case "C": return "C"
        default: throw new Error(`Unknown relationship string ${relationship} extracted from barcode ${barcode}`)
    }
}

export const SubmitSampleComments = async (specimens: Specimen[]): Promise<boolean> => {
    specimens.forEach(specimen => {
        UpdateCommentBySpecimenId(specimen.specimenId, specimen.comment)
        .then(response => {
            if (!response.success) {
                console.error(response.errorMessage);
                return false;
            }
        })
        .catch(error => {
            console.error(error);
            return false;
        })
    })

    return true;
}

export const UpdateBatchDNAConcentrationsFilename = (batch: Batch, dnaConcentrationsFilename: string): Batch => {
    return {
        ...batch,
        dnaConcentrationsFilename: dnaConcentrationsFilename
    };
};

export const ProcessSpectrophotometerDNAConcentrations = (spectrophotometerRows: any[], batchName: string): DNAConcentrations => {
    const wavelengths = ['230', '260', '280', '320'];

    var dnaConcentrations: DNAConcentrations =
    {
        batchName: batchName,
        concentrations230nm: [],
        concentrations260nm: [],
        concentrations280nm: [],
        concentrations320nm: []
    };

    var spectra = new SpectraHelper();
    try {
        spectra.parse(spectrophotometerRows.join("\r\n"));
    } catch(error) {
        let errorMessage = "Failed to read DNA concentrations from spectrophotometer file. ";
        if (error instanceof Error) {
            console.log(errorMessage + error.message)
            throw new Error(errorMessage + error.message);
        }
        else {
            console.log(errorMessage + String(error));
            throw new Error(errorMessage + String(error));
        }
    }

    spectra.logicalPlates.forEach(plate => {
        wavelengths.forEach(wavelength => {
            for (let well in plate.data.readings[wavelength]) {
                var dnaConcentration: BatchDNAConcentrations = {
                    wellNumber: well,
                    concentration: wavelength === '260' ? plate.data.wellCalculations['mydna_260'][well].toString() : plate.data.readings[wavelength][well].toString()
                }
                if (dnaConcentration.wellNumber && dnaConcentration.concentration) {
                    switch (wavelength) {
                        case '230':
                            dnaConcentrations.concentrations230nm.push(dnaConcentration);
                            break;
                        case '260':
                            dnaConcentrations.concentrations260nm.push(dnaConcentration); 
                            break;
                        case '280':
                            dnaConcentrations.concentrations280nm.push(dnaConcentration); 
                            break;
                        case '320':
                            dnaConcentrations.concentrations320nm.push(dnaConcentration); 
                            break;
                    }    
                }
            }
        })
    })

    return dnaConcentrations;
}
