import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = styled.div`
    padding: 0.5rem;
`;

export const SideBySideWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;

    justify-content: center;
`;

export const ComponentWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    padding: 10rem;
`;

export const RightPadding = styled.div`
    padding: 0 1rem 0 0;
`;

export const Subtitle = styled.div`
    color: #808080;
    font-style: italic;
    font-size: 12px;

    padding: 0.5rem 0;
`;