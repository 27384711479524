import React, { useEffect, useState, useRef, useContext } from "react";
import { RootState } from "../../../../store";
import { ContentWrapper, SectionWrapper, DescriptionWrapper, DetailWrapper, HiddenComponent, PrintTableStyles, ButtonWrapper, SpinnerWrapper } from "./styles";
import { Divider, Typography, Box, Snackbar, Modal } from '@mui/material';
import Batch from "../../../../Models/Batch";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Specimen from "../../../../Models/Specimen";
import Patient from "../../../../Models/Patient";
import { MapPanelConfigToRows, GenerateABIDataFile, UpdateBatchDNAConcentrationsFilename, ProcessSpectrophotometerDNAConcentrations, GenerateDilutionCSVFile } from './utils';
import SortBatchReportDataByWellNumbers from '../../../../Utils/SortBatchDataByWellNumbers';
import WellType from "../../../../Models/WellType";
import BatchReportData from "../../../../Models/BatchReportData";
import PanelConfigType from "../../../../Models/PanelConfigType";
import PrintBatchTable from '../../../../Components/PrintBatchTable';
import { useDispatch, useSelector } from "react-redux";
import TestTable from '../../../../Models/TestTable';
import PanelDataTable from '../../../../Models/PanelDataTable';
import { ArchiveBatch, ArchiveSpecimens, ArchivePatients, UpsertSpecimens, UpsertPatient, SpecimenError, UpsertDNAConcentrations, GetDNADilutions } from "../../../../Graphql/Api";
import { AuthContext } from "../../../../Context/Auth/AuthContext";
import AuthContextType from "../../../../Models/AuthContextType";
import { CircularProgress } from '@mui/material';
import { removeBatch } from "../../../../ReduxSlices/batchSlice";
import { removeSpecimens, updateSpecimen } from '../../../../ReduxSlices/specimenSlice';
import { removePatients, updatePatient } from '../../../../ReduxSlices/patientSlice';
import { PaternityABIDataDefaults } from "../../../../Models/Constants/ABIDataConstants";
import MuiAlert from '@mui/material/Alert';
import { ModalStyle } from "../CreateBatchTab/styles";
import WarningIcon from '@mui/icons-material/Warning';
import { AppModeConfig } from "../../../../AppModes/AppModeConfig";
import { AppModes } from '../../../../Models/Constants/AppModes';
import { CreateBatchScreenFields } from '../../../../Models/AppConfig/CreateBatchScreenFields';
import CSVReader from "react-csv-reader";
import DNADilution from "../../../../Models/DNADilution";

interface ViewBatchesTabProps {
    preSelectedBatch?: Batch,
    onStateUpdate: () => void,
    appModeConfig: AppModeConfig,
}

const ViewBatchesTab = ({ preSelectedBatch, onStateUpdate, appModeConfig }: ViewBatchesTabProps) => {

    var authContext = useContext(AuthContext) as AuthContextType;
    const dispatch = useDispatch();

    var printTableRef = useRef<HTMLDivElement>(null);

    const batchState = useSelector((state: RootState) => state.batches.batches);
    const specimenState = useSelector((state: RootState) => state.specimens.specimens);
    const patientState = useSelector((state: RootState) => state.patients.patients);

    const [selectedBatchName, setSelectedBatchName] = useState<string>(preSelectedBatch?.name ?? "");
    const [selectedBatch, setSelectedBatch] = useState<Batch | undefined>(preSelectedBatch);
    const [batchReportData, setBatchReportData] = useState<BatchReportData[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [spectrophotometerRows, setSpectrophotometerRows] = useState<any[]>([]);

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalWarning, setModalWarning] = useState<boolean>(false);
    const [modalTextElement, setModalTextElement] = useState<JSX.Element>(<></>);

    useEffect(() => {

        const evaluateBatchReportData = (panelConfig: PanelConfigType, batch?: Batch): BatchReportData[] => {
            var result = MapPanelConfigToRows(panelConfig);
    
            batch?.specimens.forEach(batchSpecimen => {
    
                var specimen: Specimen | undefined = specimenState.find(x => x.specimenId === batchSpecimen.specimenId);
                var patient: Patient | undefined = patientState.find(x => x.sampleId === batchSpecimen.specimenId);
    
                if (!specimen) {
                    if (!patient) {                    
                        alert("Specimen could not be found in state for specimen ID " + batchSpecimen.specimenId);
                        return <></>;
                    } else {
                        specimen = { 
                            barcode: patient.sampleId,
                            specimenId: patient.sampleId,
                            firstName: patient.firstName,
                            lastName: patient.lastName,
                            dateOfBirth: patient.dateOfBirth,
                            caseId: patient.productDisplayName,
                            received: patient.sampleCollectionDate,
                            comment: "LUMI patient specimen"
                        };
                    }
                }
    
                result.push({
                    wellNumber: batchSpecimen.wellNumber,
                    wellType: WellType.STANDARD,
                    specimen: specimen,
                    patient: patient
                });
            });
    
            // Sort data numerically, then alphababetically
            var sortedResults = SortBatchReportDataByWellNumbers(result);

            // Slice off any non standard wells that are higher up than the highest sample
            // As the data is sorted, this is just the largest index of the standard well
            var highestStandardWellIndex = 0;
            result.forEach((well, index) => {
                if (well.wellType === WellType.STANDARD && index > highestStandardWellIndex) highestStandardWellIndex = index;
            })

            result = result.slice(0, highestStandardWellIndex+1);
            
            return sortedResults;
        };

        if (selectedBatchName !== undefined) {
            var batch = batchState.find(x => x.name === selectedBatchName);
            setSelectedBatch(batch);
            setBatchReportData(evaluateBatchReportData(appModeConfig.BatchConfig.defaultPanelConfig, batch));
        }
    }, [batchState, selectedBatchName, specimenState, patientState, appModeConfig.BatchConfig.defaultPanelConfig]);

    const handlePrintBatch = () => {
        if (printTableRef.current) {
            var tempWindow = window.open("");
            tempWindow!.document.write(`<html><head><style>${PrintTableStyles}</style></head><body>`)
            tempWindow!.document.write(printTableRef.current.outerHTML);
            tempWindow!.document.write("</body></html>");
            tempWindow!.print();
            tempWindow!.close();
        }
    };

    const handleArchiveBatch = async () => {

        var batchSpecimenIds = selectedBatch!.specimens.map(specimen => specimen.specimenId);

        if (!batchSpecimenIds) return;

        var batchSpecimens = specimenState.filter(specimen => batchSpecimenIds!.includes(specimen.specimenId));
        var archivingUser = authContext.user?.getUsername();

        var archiveProgress = {
            isSuccess: false,
            errorMessage: ""
        };

        if (batchSpecimens.length > 0) {
            try {
                let response = await ArchiveSpecimens(batchSpecimens, archivingUser!, selectedBatch!.name);
                archiveProgress.isSuccess = response.isSuccess;
            } catch (error) {
                archiveProgress.isSuccess = false;
                archiveProgress.errorMessage = JSON.stringify(error);
            }
    
            if (!archiveProgress.isSuccess) {
                let message = "Error archiving specimens";
                console.error(message + " - " + archiveProgress.errorMessage);
                alert(message);
                setIsLoading(false);
                return;
            }
    
            // Remove specimens from application state
            dispatch(removeSpecimens(batchSpecimens));
        }

        var batchPatients = patientState.filter(patient => batchSpecimenIds!.includes(patient.sampleId));

        if (batchPatients.length > 0) {
            try {
                let response = await ArchivePatients(batchPatients, archivingUser!, selectedBatch!.name);
                archiveProgress.isSuccess = response.isSuccess;
            } catch (error) {
                archiveProgress.isSuccess = false;
                archiveProgress.errorMessage = JSON.stringify(error);
            }
    
            if (!archiveProgress.isSuccess) {
                let message = "Error archiving patients";
                console.error(message + " - " + archiveProgress.errorMessage);
                alert(message);
                setIsLoading(false);
                return;
            }
    
            // Remove patients from application state
            dispatch(removePatients(batchPatients));
            
        }

        try {
            let response = await ArchiveBatch(selectedBatch!, archivingUser!);
            archiveProgress.isSuccess = response.isSuccess;
        } catch (error) {
            archiveProgress.isSuccess = false;
            archiveProgress.errorMessage = JSON.stringify(error);
        }

        if (!archiveProgress.isSuccess) {
            let message = "Error archiving batch";
            console.error(message + " - " + archiveProgress.errorMessage);
            alert(message);
            setIsLoading(false);
            return;
        }

        // Remove the batch from application and local
        dispatch(removeBatch(selectedBatch!));
        setSelectedBatchName("");
        setSelectedBatch(undefined);
    };

    const handleGenerateABIFile = () => {

        setIsLoading(true);

        // Hard-coding Paternity defaults for now
        var defaults = PaternityABIDataDefaults;

        GenerateABIDataFile(defaults, selectedBatch!, batchReportData!)
        .then(() => {
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.error(error);
            alert('Error generating ABI 3500XL data file'); // todo replace with toast
        })
    };

    const handleDilutionCSVFile = () => {
        setIsLoading(true);

        GetDNADilutions(selectedBatchName!)
        .then((data: DNADilution[]) => {
            if (data && data.length === 0) {
                setIsLoading(false);
                alert("Could not find any dna dilution data for this batch.")
                return
            }

            GenerateDilutionCSVFile(selectedBatch!, data)
            .then(() => {
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
                alert('Error generating dilution csv file');
            })
        })
        .catch(error => {
            var ErrorMessage = "Error when getting dna dilution data for this batch. ";
            if (error instanceof Error) {
                console.error(ErrorMessage.concat(error.message));
                setToastError(ErrorMessage);
            }
            else {
                console.error(ErrorMessage.concat(error.errors[0].message));
                setToastError(ErrorMessage);
            }
            
            setToastOpen(true);
        });
    };

    const handleEditSpecimenComment = (specimen: Specimen) => {
        setIsLoading(true);
        
        // update specimen in database
        UpsertSpecimens([specimen])
        .then(response => {
            // update specimen in state
            if (!response.isSuccess) {
                setToastError("Error when attempting to update specimen comment");
                return;
            }
            
            dispatch(updateSpecimen(specimen));
            onStateUpdate();
        })
        .catch(error => {
            setToastError("Error when attempting to update specimen comment");
            console.error("Error when attempting to update specimen comment - " + error);
        })

        setIsLoading(false);
        setToastOpen(true);
    }

    const handleReportSpecimenError = (specimen: Specimen, sampleErrorNote: string) => {
        setIsLoading(true);

        var batchedPatient = patientState.find(patient => patient.sampleId === specimen.specimenId);
        if (batchedPatient) {
            var patient: Patient = {...batchedPatient, dateOfBirth: new Date(batchedPatient.dateOfBirth), sampleCollectionDate: new Date(batchedPatient.sampleCollectionDate), sampleErrorNote: sampleErrorNote};

            UpsertPatient(patient)
            .then(response => {
                // update patient in state
                if (!response.isSuccess) {
                    setToastError("Error when attempting to save patient details");
                    return;
                }

                dispatch(updatePatient(patient));
                onStateUpdate();
            })
            .catch(error => {
                let errorMessage = "Error when attempting to save patient details. ";
                if (error instanceof Error) 
                    console.error(errorMessage + error.message)
                else
                    console.error(errorMessage + String(error));

                setToastError(errorMessage);
            })

            SpecimenError(patient.sampleId, patient.journeyId, patient.orderId, patient.id, patient.productCode, patient.sampleErrorNote)
                .then(response => {
                    if (!response.success) {
                        setToastError("Error when attempting to update LUMI with specimenError");
                        return;
                    }
                })
                .catch(error => {
                    var ErrorMessage = "Error when attempting to update LUMI with sample error note.";
                    if (error.errors.length > 0 && JSON.parse(error.errors[0].errorInfo).Success === false) {
                        var ConsoleErrorMessage = ErrorMessage
                        ConsoleErrorMessage.concat("\r\n");
                        ConsoleErrorMessage.concat(JSON.parse(error.errors[0].errorInfo).message);
                        console.error(ConsoleErrorMessage);
                        setToastError(ErrorMessage);
                    } 
                    else {
                        console.error(ErrorMessage);
                        setToastError(ErrorMessage);
                    }
                })
            setToastOpen(true);

        }
        setIsLoading(false);
    }

    const handleToastClose = () => {
        setToastOpen(false);
        setToastError("");
    }

    const handleCompleteBatch = async () => {
        setIsLoading(true);

        var result = await appModeConfig.BatchConfig.completeBatch(selectedBatch!, specimenState, patientState);

        if (!result.isSuccess) {
            setModalTextElement(<>{result.messages.map(message => <div>{message}</div>)}</>);
            setModalWarning(true);
            setModalOpen(true);
            result.messages.map(message => console.error(JSON.parse(message).errors[0].errorInfo));
        }

        // Finally, archive the batch
        await handleArchiveBatch();

        setIsLoading(false);
        setModalOpen(false);
        onStateUpdate();
    }

    const handleSpectrophotometerSelection = (data: any[], fileInfo: any, originalFile: File | undefined) => {
        setSelectedBatch(UpdateBatchDNAConcentrationsFilename(selectedBatch!, originalFile!.name));
        setSpectrophotometerRows(data);
    };

    const handleImportDNAConcentrationsAsync = async () => {
        if (spectrophotometerRows.length === 0) {
            alert("Please select a spectrophotometer file containing the dna concentrations.")
            return;
        }

        try {
            var dnaConcentrations = ProcessSpectrophotometerDNAConcentrations(spectrophotometerRows, selectedBatch?.name ?? "");
            await UpsertDNAConcentrations(dnaConcentrations);
        } catch(error) {
            if (error instanceof Error) 
                setToastError(error.message)
            else 
                setToastError(String(error))
            
            setToastOpen(true);
        }
    };

    const onCompleteBatchButtonClicked = () => {
        // Temp disabling this screen and just submitting
        // Todo: Need to make this modal scrollable
        //setSubmitReviewModalOpen(true);
        handleCompleteBatch();
    }

    const toggleModal = () => {
        setModalOpen(modalOpen => !modalOpen);
    }

    const spectrophotometerFileParserOptions = {
        delimiter: ","
    }

    // Render fields dependent on app mode
    const optionalFields = () => {

        var fields: JSX.Element[] = [];

        if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.DNACONCENTRATION)) {
            fields.push(
                <div key={'dna-concentration-input'}>
                    <Divider variant="middle" />
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                            DNA Concentration file:{" "}
                        </Typography>
                        <CSVReader accept=".txt, text/txt" onFileLoaded={handleSpectrophotometerSelection} parserOptions={spectrophotometerFileParserOptions}/>
                    </SectionWrapper>
                        <ButtonWrapper>
                            <Button 
                                onClick={handleImportDNAConcentrationsAsync}
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                Save Concentrations
                            </Button>
                        </ButtonWrapper>
                    <Divider variant="middle" />
                </div>
            );
        }

        return fields;
    }

    const displayBatch = () => {
        return (
            <>
                {!isLoading &&
                <>
                    <SectionWrapper>
                        <DescriptionWrapper>
                            <DetailWrapper>
                                <Typography gutterBottom variant="h6" component="div">
                                    {selectedBatch!.name}
                                </Typography>
                            </DetailWrapper>
                            {selectedBatch!.rackBarcode &&
                            <DetailWrapper>
                                {`Rack barcode: ${selectedBatch!.rackBarcode}`}
                            </DetailWrapper>}
                            {selectedBatch!.plateBarcode &&
                            <DetailWrapper>
                                {`Plate barcode: ${selectedBatch!.plateBarcode}`}
                            </DetailWrapper>}
                            {selectedBatch!.tube2DBarcodesFilename &&
                            <DetailWrapper>
                                {`Tube 2D barcode file: ${selectedBatch!.tube2DBarcodesFilename}`}
                            </DetailWrapper>}
                            <DetailWrapper>
                                {`Date created: ${selectedBatch!.dateCreated}`}
                            </DetailWrapper>
                            <DetailWrapper>
                                {`Created by: ${selectedBatch!.createdBy}`}
                            </DetailWrapper>
                        </DescriptionWrapper>
                        <ButtonWrapper>
                            <Button 
                                onClick={handlePrintBatch} 
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                Print Batch
                            </Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Button 
                                onClick={onCompleteBatchButtonClicked} 
                                variant="contained"
                                color="secondary"
                                disabled={isLoading}
                            >
                                Complete Batch
                            </Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                            <Button 
                                onClick={() => handleGenerateABIFile()} 
                                variant="contained"
                                color="warning"
                                disabled={isLoading}
                            >
                                Generate ABI 3500XL data file
                            </Button>
                        </ButtonWrapper>
                        {appModeConfig.name === AppModes.CARRIERSCREENING &&
                        <ButtonWrapper>
                            <Button 
                                onClick={() => handleDilutionCSVFile()} 
                                variant="contained"
                                color="warning"
                                disabled={isLoading}
                            >
                                Generate Dilution data file
                            </Button>
                        </ButtonWrapper>}
                    </SectionWrapper>
                    {optionalFields()}
                    <Divider variant="middle" />
                    {batchReportData &&
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="div">
                            Panel data
                        </Typography>
                        <PanelDataTable 
                            data={batchReportData} 
                            isEditable={true} 
                            onEditSpecimenComment={handleEditSpecimenComment}
                            onReportSpecimenError={handleReportSpecimenError} 
                            appModeConfig={appModeConfig}
                        />
                    </SectionWrapper>}
                    <Divider variant="middle" />
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="div">
                            Test data
                        </Typography>
                        <TestTable 
                            batch={selectedBatch}
                            appModeConfig={appModeConfig}
                        />
                    </SectionWrapper>
                    {batchReportData &&
                    <HiddenComponent>
                        <div ref={printTableRef} >
                            <PrintBatchTable data={batchReportData} />
                        </div>
                    </HiddenComponent>}
                </>}
                {isLoading &&
                <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>}
                <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                    <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                        {toastError === "" ? "Success!" : toastError}
                    </MuiAlert>
                </Snackbar>
                <Modal
                    open={modalOpen}
                    onClose={() => toggleModal()}
                >
                    <Box sx={ModalStyle}>
                        {modalWarning &&
                        <WarningIcon />}
                        <Typography variant="h6" component="h2">
                            {modalTextElement}
                        </Typography>
                        <Button color="error" variant="contained" onClick={toggleModal}>CLOSE</Button>
                    </Box>
                </Modal>
            </>
        )
    }

    const content = selectedBatch ? displayBatch() : <></>;

    const handleSelectBatch = (event: SelectChangeEvent<string>) => {
        setSelectedBatchName(event.target.value);
    };

    const batchSelectorContent = () => {
        var menuItems = batchState.map((batch, index) => {
            return <MenuItem value={batch.name} key={`batch-${index}`} >{batch.name}</MenuItem>
        });

        return (
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="batch-selector-input-label">Batches</InputLabel>
                    <Select
                    labelId="batch-selector-select-label"
                    id="batch-selector-selector"
                    value={selectedBatchName}
                    label="Batches"
                    onChange={handleSelectBatch}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </Box>
        );
    }

    return (
        <ContentWrapper>
            <SectionWrapper>   
                <Typography gutterBottom variant="h4" component="div">
                    View Batches
                </Typography>
                {batchSelectorContent()}
            </SectionWrapper>
            <Divider variant="middle" />
            {content}
        </ContentWrapper>
    );
}

export default ViewBatchesTab;