import { CircularProgress, Snackbar, TextField } from '@mui/material';
import { Typography, Divider } from '@mui/material';
import React, { useRef, useState } from 'react';
import { GetAllPatientsBySampleId, GetAllPatientsByName, UpsertPatient, UnarchivePatient } from '../../../../Graphql/Api';
import {ContentWrapper, SectionWrapper, SideBySideWrapper, ComponentWrapper, SpinnerWrapper, RightPadding, Subtitle } from './styles';
import MuiAlert from '@mui/material/Alert';
import Patient from '../../../../Models/Patient';
import ArchivedPatient from '../../../../Models/ArchivedPatient';
import SearchPatientTable from './Components/SearchPatientTable';
import GraphqlExceptionLogging from '../../../../Utils/GraphqlExceptionLogging';

interface DisplayPatientData {
    patients: Patient[],
    archivedPatients: ArchivedPatient[]
}

interface PatientsTabProps {
    onNavigateToAnyBatch:(batchName: string, isArchived: boolean) => void
}

const PatientsTab = ({ onNavigateToAnyBatch }: PatientsTabProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displayPatients, setDisplayPatients] = useState<DisplayPatientData>({patients: [], archivedPatients: []});

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const firstNameRef = useRef<HTMLInputElement | null>(null);

    function convertDateTypeFields(data: DisplayPatientData) : DisplayPatientData {
        data.patients?.forEach(patient => {
            patient.dateOfBirth = new Date(patient.dateOfBirth);
            patient.sampleCollectionDate = new Date(patient.sampleCollectionDate);
            patient.dateCreated = new Date(patient.dateCreated);
        });
        data.archivedPatients?.forEach(archivedPatient => {
            archivedPatient.dateOfBirth = new Date(archivedPatient.dateOfBirth);
            archivedPatient.sampleCollectionDate = new Date(archivedPatient.sampleCollectionDate);
            archivedPatient.dateCreated = new Date(archivedPatient.dateCreated);
        });
        return data;
    }

    const handleSampleIdInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") return; 

        var target = event.target as HTMLInputElement;
        var sampleId = target.value;

        if (sampleId === "") {
            setDisplayPatients({patients: [], archivedPatients: []});
            return;
        }

        setIsLoading(true);
        
        GetAllPatientsBySampleId(sampleId)
        .then((data: DisplayPatientData) => {
            setDisplayPatients(convertDateTypeFields(data));
        })
        .catch(error => {
            var message = "Error when getting all patients by sample id";
            setToastError(message);
            console.error(message);
            error.errors.forEach((error: Error) => {
                console.error(error.message)
            });
            setToastOpen(true);
        })

        setIsLoading(false);
    }

    const handleNameInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") return; 

        var lastName = lastNameRef.current?.value;
        var firstName = firstNameRef.current?.value;

        if (lastName === "") {
            setDisplayPatients({patients: [], archivedPatients: []});
            return;
        }

        setIsLoading(true);

        GetAllPatientsByName(lastName!, firstName!)
        .then((data: DisplayPatientData) => {
            setDisplayPatients(convertDateTypeFields(data));
        })
        .catch(error => {
            var message = "Error when getting all patients by name";
            setToastError(message);
            console.error(message);
            error.errors.forEach((error: Error) => {
                console.error(error.message)
            });

            setToastOpen(true);
        })

        setIsLoading(false);
    }

    const onPatientVCGSToggle = (selectedPatient: Patient) => {
        var index = displayPatients.patients.findIndex(patient => patient.sampleId === selectedPatient.sampleId);
        if (index !== -1) {
            displayPatients.patients[index].addToVCGSManifest = !displayPatients.patients[index].addToVCGSManifest;
            setDisplayPatients({patients: displayPatients.patients, archivedPatients: displayPatients.archivedPatients});
        }
    }

    const onPatientGeneByGeneToggle = (selectedPatient: Patient) => {
        var index = displayPatients.patients.findIndex(patient => patient.sampleId === selectedPatient.sampleId);
        if (index !== -1) {
            displayPatients.patients[index].addToGeneByGeneManifest = !displayPatients.patients[index].addToGeneByGeneManifest;
            setDisplayPatients({patients: displayPatients.patients, archivedPatients: displayPatients.archivedPatients});
        }
    }

    const onSavePatientToggles = (selectedPatient: Patient) => {
        UpsertPatient(selectedPatient)
            .then(response => {
                if (!response.isSuccess) {
                    setToastError("Error when attempting to save patient details");
                    setToastOpen(true);
                }
            })
            .catch(error => {
                let errorMessage = "Error when attempting to save patient details. ";
                GraphqlExceptionLogging(error, errorMessage);
                setToastError(errorMessage);
                setToastOpen(true);
            })
    }

    const onUnarchivePatient = (selectedSampleId: string) => {
        UnarchivePatient(selectedSampleId)
            .then(response => {
                if (!response.isSuccess) {
                    setToastError("Error when attempting to unarchive a patient");
                }
                setToastOpen(true);
            })
            .catch(error => {
                let errorMessage = "Error when attempting to unarchive a patient. ";
                GraphqlExceptionLogging(error, errorMessage);
                setToastError(errorMessage);
                setToastOpen(true);
            })
    }

    const handleToastClose = () => {
        setToastOpen(false);
        setToastError("");
    }
    
    return (
        <>
            {isLoading &&
                <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>
            }
            {!isLoading &&
            <ContentWrapper>
                <SectionWrapper>   
                    <Typography gutterBottom variant="h4" component="div">
                        Search
                    </Typography>
                    <Subtitle>Press Enter to search</Subtitle>
                </SectionWrapper>
                <Divider variant="middle" />
                <SectionWrapper>
                    <ComponentWrapper>
                        <TextField 
                            id="sample-id-input"
                            variant="standard" 
                            onKeyUp={handleSampleIdInput}
                            label="Sample ID"
                        />
                    </ComponentWrapper>
                </SectionWrapper>
                <Divider variant="middle" />
                <SectionWrapper>
                    <Subtitle>Last name is mandatory, first name is optional. The search will only match exactly.</Subtitle>
                    <SideBySideWrapper>
                        <ComponentWrapper>
                            <RightPadding>
                                <TextField 
                                    id="patient-name-input"
                                    variant="standard" 
                                    label="Last name"
                                    inputRef={lastNameRef}
                                    onKeyUp={handleNameInput}
                                />
                            </RightPadding>
                            <TextField 
                                id="patient-name-input"
                                variant="standard" 
                                label="First name"
                                inputRef={firstNameRef}
                                onKeyUp={handleNameInput}
                            />
                        </ComponentWrapper>
                    </SideBySideWrapper>
                </SectionWrapper>
            </ContentWrapper>}
            <Divider variant="middle" />
            <SearchPatientTable 
                activePatients={displayPatients.patients} 
                archivedPatients={displayPatients.archivedPatients}
                onNavigateToBatch={onNavigateToAnyBatch} 
                onPatientVCGSToggle={onPatientVCGSToggle}
                onPatientGeneByGeneToggle={onPatientGeneByGeneToggle}
                onSavePatientToggles={onSavePatientToggles}
                onUnarchivePatient={onUnarchivePatient}
            />
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                    {toastError === "" ? "Success!" : toastError}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default PatientsTab;