import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = styled.div`
    padding: 0.5rem;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DetailWrapper = styled.div`
    display: flex;
`;

export const HiddenComponent = styled.div`
    display: none;
`;

export const PrintTableStyles = `
    @media print{
        @page {
            size: landscape
        }
    }

    table, td, th {
        border: 1px solid;
    }   

    table {
        width: 100%;
        border-collapse: collapse;
    }
`;

export const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

export const ChipWrapper = styled.div`
    display: flex;
    width: 100%;

    padding: 0.5rem 0;
`;

export const ButtonWrapper = styled.div`
    padding: 0.5rem;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    padding: 10rem;
`;