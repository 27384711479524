import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { GetBatches, GetSpecimens, GetPatients } from "../../Graphql/Api";
import { setBatches } from '../../ReduxSlices/batchSlice';
import { setSpecimens } from '../../ReduxSlices/specimenSlice';
import { setPatients } from '../../ReduxSlices/patientSlice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TabPanel from '../../Components/TabPanel';
import CreateBatchTab from './Tabs/CreateBatchTab';
import ViewBatchesTab from './Tabs/ViewBatchesTab';
import ArchivedBatchesTab from './Tabs/ArchivedBatchesTab';
import OutstandingPatientsTab from './Tabs/OutstandingPatientsTab';
import PatientsTab from './Tabs/PatientsTab';
import SearchTab from './Tabs/SearchTab';
import Batch from '../../Models/Batch';
import Specimen from '../../Models/Specimen';
import Patient from '../../Models/Patient';
import { RootState } from '../../store';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { SpinnerWrapper } from './styles';
import { AppModeContext } from '../../Context/AppMode/AppModeContext';
import AppModeContextType from '../../Models/AppModeContextType';
import { AppModeConfig, ReturnAppModeConfig } from '../../AppModes/AppModeConfig';
import { AreAnyOutstandingPatientsCritical, DetermineOutstandingPatients } from './utils';
import WarningIcon from '@mui/icons-material/Warning';
import { BatchTabs } from '../../Models/BatchTabs';
import { BatchTab } from '../../Models/BatchTab';

const BatchesPage = () => {
    const batchState = useSelector((state: RootState) => state.batches.batches);

    const appModeContext = useContext(AppModeContext) as AppModeContextType;

    const dispatch = useDispatch();

    const [batches, setBatchData] = useState<Batch[] | null>(null);
    const [specimens, setSpecimenData] = useState<Specimen[] | null>(null);
    const [patients, setPatientData] = useState<Patient[] | null>(null);

    const [outstandingPatients, setOutstandingPatients] = useState<Patient[] | null>(null);
    const [criticalOutstanding, setCriticalOutstanding] = useState<boolean>(false);

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const [preSelectedBatch, setPreSelectedBatch] = useState<Batch | undefined>(undefined);
    const [preSelectedArchivedBatchName, setPreSelectedArchivedBatchName] = useState<string>("");

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const appModeConfig = ReturnAppModeConfig(appModeContext.mode);

    const updateApplicationState = () => {

        setToastError("");

        GetBatches()
        .then(batches => {
            dispatch(setBatches(batches));
            setBatchData(batches);
        })
        .catch(error => {
            var message = "Error fetching Batch data from database";
            setToastError(toastError + " " + message)
            console.error(message);
            console.error(error);
            setToastOpen(true);
        });

        GetSpecimens()
        .then(specimens => {
            dispatch(setSpecimens(specimens));
            setSpecimenData(specimens);
        })
        .catch(error => {
            var message = "Error fetching Specimen data from database";
            setToastError(toastError + " " + message);
            console.error(message);
            console.error(error);
            setToastOpen(true);
        });

        GetPatients()
        .then(patients => {
            dispatch(setPatients(patients));
            setPatientData(patients);

            var outstandingPatients = DetermineOutstandingPatients(patients, batchState);
            setOutstandingPatients(outstandingPatients);

            setCriticalOutstanding(AreAnyOutstandingPatientsCritical(outstandingPatients));
        })
        .catch(error => {
            var message = "Error fetching Lumi Patients data from database";
            setToastError(toastError + " " + message);
            console.error(message);
            console.error(error);
            setToastOpen(true);
        });
        
    }

    useEffect(() => {
        updateApplicationState();
    }, []);

    const onStateUpdate = () => {
        updateApplicationState()
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabIndex(newValue);
    }

    const onNavigateToViewBatch = (batch: Batch) => {
        setPreSelectedBatch(batch);
        setActiveTabIndex(0); // View batches tab index
    }

    const onNavigateToArchivedBatch = (batchName: string) => {
        setPreSelectedArchivedBatchName(batchName);
        setActiveTabIndex(2); // Archived batches tab index
    }

    const onNavigateToAnyBatch = (batchName: string, isArchived: boolean) => {
        if (!isArchived) {
            var batch = batchState.find(batch => batch.name ===batchName);
            if (!batch) return;
            onNavigateToViewBatch(batch);
        } else {
            onNavigateToArchivedBatch(batchName);
        }
    }

    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setToastOpen(false);
    }


    const constructTabsFromAppModeConfig = (appModeConfig: AppModeConfig): BatchTab[] => {
        var tabs: BatchTab[] = [];

        appModeConfig.BatchConfig.batchTabs.forEach(batchTab => {

            var tab: BatchTab = {
                name: batchTab,
                content: <></>
            };

            switch (batchTab) {
                case BatchTabs.VIEW:
                    tab.content = 
                        <ViewBatchesTab 
                            onStateUpdate={onStateUpdate} 
                            preSelectedBatch={preSelectedBatch} 
                            appModeConfig={appModeConfig!}
                        />
                    break;
                case BatchTabs.CREATE:
                    tab.content = 
                        <CreateBatchTab 
                            onStateUpdate={onStateUpdate} 
                            onNavigateToViewBatch={onNavigateToViewBatch} 
                            appModeConfig={appModeConfig!}
                        />
                    break;
                case BatchTabs.ARCHIVED:
                    tab.content = 
                        <ArchivedBatchesTab 
                            preselectedBatchName={preSelectedArchivedBatchName} 
                            appModeConfig={appModeConfig!}
                        />
                    break;
                case BatchTabs.KINSHIPSEARCH:
                    tab.content = <SearchTab onNavigateToAnyBatch={onNavigateToAnyBatch} />
                    break;
                case BatchTabs.CARRIERSEARCH:
                    tab.content = <PatientsTab onNavigateToAnyBatch={onNavigateToAnyBatch} />
                    break;
                case BatchTabs.CARRIEROUSTANDING:
                    tab.content = <OutstandingPatientsTab patients={outstandingPatients!} />
                    if (criticalOutstanding) tab.icon = <WarningIcon color="error" />
                    break;
                default:
                    console.error("Unknown batch tab type: " + batchTab);
            }

            tabs.push(tab);
        });


        return tabs;
    }

    const tabs = constructTabsFromAppModeConfig(appModeConfig!);
    
    if (!batches || !specimens || !patients) return (
        <SpinnerWrapper>
            <CircularProgress />
        </SpinnerWrapper>
    );

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    {
                        tabs.map(tab => {
                            if (tab.icon) return <Tab label={tab.name} key={tab.name} icon={tab.icon} iconPosition="start"/>

                            return <Tab label={tab.name} key={tab.name}/>
                        })
                    }
                </Tabs>
            </Box>
            {tabs.map((tab, index) => 
            <TabPanel value={activeTabIndex} index={index} key={tab.name}>
                {tab.content}
            </TabPanel>
            )}
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                    {toastError === "" ? "Success!" : toastError}
                </MuiAlert>
            </Snackbar>
        </>
    )
};

export default BatchesPage;