import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';

export const TableWrapper = styled.div`
    padding: 0.5rem;
`;

export const CriticalRow = styled(TableRow)`
    background-color: #ffaaaa;
`;

export const RegularRow = styled(TableRow)`
    background-color: #dadada;
`;

export const NumberOfRows = styled.div`
    display: flex;
    justify-content: right;
`;