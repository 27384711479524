import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const SectionWrapper = styled.div`
    display: flex;
    padding: 0.5rem;
`;

export const TestComponentsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const TestComponent = styled.div`
    display: flex;    
    width: 25%;
`;

export const AttributeComponent = styled.div`
    display: flex;    
    width: 100%;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    padding: 5rem;
`;

export const TableWrapper = styled.div`
    padding: 0.5rem;
`;

export const StatusCellWrapper = styled.div`
    display: flex;
    width: 100%;

    flex-direction: row;
    align-items: stretch;
`;

export const StatusIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusTextWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 0.5rem;
`;

export const ModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};
