import React from "react";
import { ContentWrapper } from "./styles";
import Patient from "../../../../Models/Patient";
import { OutstandingPatientsTable } from './Components/OutstandingPatientsTable';

interface OutstandingPatientsTabProps {
    patients: Patient[]
}

const OutstandingPatientsTab = ({ patients }: OutstandingPatientsTabProps) => {

    return (
        <ContentWrapper>
            <OutstandingPatientsTable outstandingPatients={patients}/>
        </ContentWrapper>
    );

}

export default OutstandingPatientsTab;