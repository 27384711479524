import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = styled.div`
    padding: 0.5rem;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    padding: 10rem;
`;