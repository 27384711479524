import { AppModes } from "../Models/Constants/AppModes";
import { NavBarOption } from "../Models/NavBarOption";
import { CarrierScreeningModeConfig } from "./CarrierScreeningModeConfig";
import { KinshipModeConfig } from "./KinshipModeConfig";
import { CreateBatchScreenFields } from '../Models/AppConfig/CreateBatchScreenFields'; 
import { CompleteBatchResponse } from '../Models/AppConfig/CompleteBatchResponse';
import PanelConfigType from "../Models/PanelConfigType";
import Batch from "../Models/Batch";
import Patient from "../Models/Patient";
import Specimen from "../Models/Specimen";
import TestType from "../Models/TestType";
import { BatchTabs } from '../Models/BatchTabs';

export interface BatchConfig {
    fetchData: () => void,
    archiveData: () => void,
    completeBatch: (batch: Batch, specimens: Specimen[], patients: Patient[]) => Promise<CompleteBatchResponse>,
    defaultPanelConfig: PanelConfigType,
    requiredFields: CreateBatchScreenFields[],
    testTypes: TestType[],
    batchTabs: BatchTabs[]
}

export interface AppModeConfig {
    NavBarOptions: NavBarOption[],
    BatchConfig: BatchConfig,
    name: string,
}

export const ReturnAppModeConfig = (mode: AppModes) => {
    switch (mode) {
        case AppModes.KINSHIP:
            return KinshipModeConfig;
        case AppModes.CARRIERSCREENING:
            return CarrierScreeningModeConfig;
        default:
            console.log("Unknown app mode - " + mode);
            break;
    }
}