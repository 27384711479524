import SampleRegistration from "../../Models/SampleRegistration";

export const UpdatePatientFirstName = (sampleRegistration: (SampleRegistration | null), firstname: string): (SampleRegistration | null) => {
    if(sampleRegistration === null) {
        return null
    } 
    sampleRegistration.patient.firstName = firstname
    var objectCopy = Object.assign({}, sampleRegistration)
    objectCopy.patient.firstName = firstname
    return {
        ...objectCopy,
    }
};

export const UpdatePatientLastName = (sampleRegistration: SampleRegistration | null, lastname: string): SampleRegistration  | null => {
    if(sampleRegistration === null) {
        return null
    } 
    sampleRegistration.patient.lastName = lastname
    var objectCopy = Object.assign({}, sampleRegistration)
    objectCopy.patient.lastName = lastname
    return {
        ...objectCopy,
    }
};

export const UpdatePatientDateOfBirth = (sampleRegistration: SampleRegistration | null, dateOfBirth: string): SampleRegistration | null => {
    if(sampleRegistration === null) {
        return null
    } 
    sampleRegistration.patient.dateOfBirth = new Date(dateOfBirth) //   Date.parse(dateOfBirth); //   dateOfBirth
    var objectCopy = Object.assign({}, sampleRegistration)
    objectCopy.patient.dateOfBirth = new Date(dateOfBirth)
    return {
        ...objectCopy,
    }
};

export const UpdatePatientGender = (sampleRegistration: SampleRegistration | null, gender: string): SampleRegistration | null => {
    if(sampleRegistration === null) {
        return null
    } 
    sampleRegistration.patient.gender = gender
    var objectCopy = Object.assign({}, sampleRegistration)
    objectCopy.patient.gender = gender
    return {
        ...objectCopy,
    }
};

export const UpdatePatientEmail = (sampleRegistration: SampleRegistration | null, email: string): SampleRegistration | null => {
    if(sampleRegistration === null) {
        return null
    } 
    sampleRegistration.patient.email = email
    var objectCopy = Object.assign({}, sampleRegistration)
    objectCopy.patient.email = email
    return {
        ...objectCopy,
    }
};
