import styled from 'styled-components';

export const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;

    justify-content: space-between;

    flex-direction: row;
`;

export const ThirdWrapper = styled.div`
    display: flex;
    width: 33%;
`;
