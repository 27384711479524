import { createContext, useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { login, logout } from './AuthMethods';
import AuthContextType from '../../Models/AuthContextType';

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthContextProvider = (props: any) => {

    const [authUser, setAuthUser] = useState<CognitoUser | null>(null);

    return (
        <AuthContext.Provider value={{
            user: authUser,
            setUser: setAuthUser,
            login,
            logout
        }} >
            {props.children}
        </AuthContext.Provider>
    );
}
