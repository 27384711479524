import {
    createBrowserRouter,
    RouterProvider,
    Outlet
} from "react-router-dom";
import LandingPage from "../Pages/Landing";
import LoginPage from '../Pages/Login';
import SpecimenReceptionPage from '../Pages/SpecimenReception';
import BatchesPage from '../Pages/Batches';
import TestingPage from '../Pages/Testing';
import Header from '../Components/Header';
import { useNavigate } from "react-router-dom";
import PageWrapper from '../Components/PageWrapper';
import NavBar from '../Components/NavBar';
import { useContext } from "react";
import { AuthContext } from "../Context/Auth/AuthContext";
import AuthContextType from "../Models/AuthContextType";

// Used to apply the same layout content to all pages
const Layout = () => (
    <>
        <Header />
        <NavBar />
        <PageWrapper>
            <Outlet />
        </PageWrapper>
    </>
);

// Navigate to login page if unauthorized
export const ProtectedRoute = (props: any): JSX.Element => {

    const authContext = useContext(AuthContext) as AuthContextType;
    
    var navigate = useNavigate();


    if (authContext.user) {
        return props.children;
    }

    navigate("/login");
    return <></>;
};

const routes = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: "/",
                element: 
                    <ProtectedRoute>
                        <LandingPage />
                    </ProtectedRoute>
            },
            {
                path: "/specimenreception",
                element: 
                    <ProtectedRoute>
                        <SpecimenReceptionPage />
                    </ProtectedRoute>
            },
            {
                path: "/batches",
                element: 
                    <ProtectedRoute>
                        <BatchesPage />
                    </ProtectedRoute>
            },
            {
                path: "/testing",
                element: 
                    <ProtectedRoute>
                        <TestingPage />
                    </ProtectedRoute>
            },
            {
                path: "/login",
                element: <LoginPage />
            }
        ]
    }
]);

const AppRouter = () => {
    return <RouterProvider router={routes} />
};

export default AppRouter;
