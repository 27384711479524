import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableWrapper } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import Patient from '../../../../../../Models/Patient';
import Batch from '../../../../../../Models/Batch';
import ArchivedPatient from '../../../../../../Models/ArchivedPatient';
import { PatientRow } from '../PatientRow';

interface SearchPatientTableProps {
    activePatients: Patient[],
    archivedPatients: ArchivedPatient[],
    onNavigateToBatch: (batchName: string, isArchived: boolean) => void,
    onPatientVCGSToggle: (patient: Patient) => void,
    onPatientGeneByGeneToggle: (patient: Patient) => void,
    onSavePatientToggles: (patient: Patient) => void,
    onUnarchivePatient: (sampleId: string) => void
}

const SearchPatientTable = ({ activePatients, archivedPatients, onNavigateToBatch, onPatientVCGSToggle, onPatientGeneByGeneToggle, onSavePatientToggles, onUnarchivePatient }: SearchPatientTableProps) => {
    const batchState = useSelector((state: RootState) => state.batches.batches);

    const TableHeaders = ["", "Status", "Sample ID", "Name", "Batch"];
    const rows: JSX.Element[] =  [];
    
    activePatients?.forEach(patient => {
        let status: "outstanding" | "batched" = "outstanding";

        // Check state for batch
        var batch = batchState.find(batch => batch.specimens.map(x => x.specimenId).includes(patient.sampleId));

        if (batch) {
            status = "batched";
        }

        rows.push(
            <PatientRow 
                status={status}
                patient={patient}
                batch={batch}
                onClick={onNavigateToBatch}
                onPatientVCGSToggle={onPatientVCGSToggle}
                onPatientGeneByGeneToggle={onPatientGeneByGeneToggle}
                onSavePatientToggles={onSavePatientToggles}
                onUnarchivePatient={onUnarchivePatient}
                key={`patient-row-${patient.sampleId}`}
            />
        )
    });

    archivedPatients?.forEach(archivedPatient => {
        var batch: Batch = 
        {
            name: archivedPatient.batchName,
            rackBarcode: "",
            plateBarcode: "",
            tube2DBarcodesFilename: "",
            dnaConcentrationsFilename: "",
            specimens: [],
            createdBy: "",
            dateCreated: new Date(),
            attributes: []
        }

        rows.push(
            <PatientRow 
                status="archived"
                patient={archivedPatient}
                batch={batch}  
                onClick={onNavigateToBatch}
                onPatientVCGSToggle={onPatientVCGSToggle}
                onPatientGeneByGeneToggle={onPatientGeneByGeneToggle}
                onSavePatientToggles={onSavePatientToggles}
                onUnarchivePatient={onUnarchivePatient}
                key={`archivedpatient-row-${archivedPatient.sampleId}`}
            />
        )
    });


    return (
        <TableWrapper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        {TableHeaders.map((header, index) => <TableCell key={`header-${index}`}>{header}</TableCell>)}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableWrapper>
        );
}

export default SearchPatientTable;