import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = styled.div`
    padding: 0.5rem;
`;

export const Buffer = styled.div`
    width: 100%;
    height: 10rem;
`;

export const StickyFooter = styled.div`
    background-color: #FFFFFF;
    border-top: 4px solid #e7e7e7;
    padding: 1.5rem 3rem;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 8rem;
    width: 100%;
`;

export const SideBySide = styled.div`
    display: flex;
    flex-direction: row;
`;

export const BatchProgressTitle = styled.div`
    width: 15%;
`;

export const BatchProgressWindow = styled.div`
    display: flex;
`;

export const ModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export const ChipWrapper = styled.div`
    padding: 0.5rem;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    padding: 10rem;
    justify-content: center;
`;