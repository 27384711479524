import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArchivedBatch from "../../../../../../Models/ArchivedBatch";
import ArchivedSpecimen from "../../../../../../Models/ArchivedSpecimen";
import ArchivedPatient from "../../../../../../Models/ArchivedPatient";
import PanelDataTable from "../../../../../../Models/PanelDataTable";
import { Divider } from "@mui/material";
import { GeneratePanelData } from './utils';
import TestTable from "../../../../../../Models/TestTable";
import { SectionWrapper } from './styles';
import { AppModeConfig } from "../../../../../../AppModes/AppModeConfig";

interface ExpandableBatchContentProps {
    batch: ArchivedBatch,
    appModeConfig: AppModeConfig,
    specimens: ArchivedSpecimen[],
    patients: ArchivedPatient[]
}

const ExpandedBatchContent = ({ batch, specimens, patients, appModeConfig }: ExpandableBatchContentProps) => {

    return (
        <Box sx={{ margin: 1 }}>
            <SectionWrapper>
                <Typography variant="h6" gutterBottom component="div">
                    Panel data
                </Typography>
                <PanelDataTable 
                    data={GeneratePanelData(batch, specimens, patients)}
                    appModeConfig={appModeConfig}
                />
            </SectionWrapper>
            <Divider variant="middle" />
            <SectionWrapper>
                <Typography variant="h6" gutterBottom component="div">
                    Test data
                </Typography>
                <TestTable batch={batch} appModeConfig={appModeConfig} />
            </SectionWrapper>
        </Box>
    )
}

export default ExpandedBatchContent;