import ABIDataPlateInformation from "../ABIDataPlateInformation";
import ABIDataSampleInformation from "../ABIDataSampleInformation";

export interface ABIDataConstants {
    plateInformation: ABIDataPlateInformation,
    sampleInformation: ABIDataSampleInformation
}

export const PaternityABIDataDefaults: ABIDataConstants = {
    plateInformation: {
        plateName: "",
        applicationType: "HID",
        capillaryLength: "36",
        polymer: "POP4",
        numberOfWells: "96",
        ownerName: "",
        barcodeNumber: "",
        plateComments: ""
    },
    sampleInformation: {
        well: "",
        assay: "Investigator 26plex", // Do not modify: Must exactly match assay name in Lab software
        sampleName: "",
        fileNameConvention: "Standard", // Do not modify: Must exactly match file name convention setup in Lab software
        resultsGroup: "",
        sampleType: "",
        userField1: "", // File name convention is expecting this to be Case ID
        userField2: "", // File name convention is expecting this to be Relationship (F, M or C)
        userField3: "", // File name convention is expecting this to be Specimen ID
        userField4: "",
        userField5: "",
        sampleComments: ""
    }
}