import ArchivedSpecimen from '../../../../Models/ArchivedSpecimen';
import ArchivedPatient from '../../../../Models/ArchivedPatient';
import ArchivedBatch from '../../../../Models/ArchivedBatch';

export const MapSpecimensToBatches = (batches: ArchivedBatch[], specimens: ArchivedSpecimen[]): Map<ArchivedBatch, ArchivedSpecimen[]> => {
    var result = new Map<ArchivedBatch, ArchivedSpecimen[]>();

    batches.forEach(batch => {

        let batchSpecimens: ArchivedSpecimen[] = [];
        let regex = new RegExp("LU[ABCDEFGHJKLMNPQRTUVWXYZ2346789]{6}");

        batch.specimens.forEach(batchSpecimen => {
            if (!regex.test(batchSpecimen.specimenId)) {
                let specimen = specimens.find(specimen => specimen.specimenId === batchSpecimen.specimenId);
                if (specimen) {
                    batchSpecimens.push(specimen!);
                    result.set(batch, batchSpecimens);
                } else {
                    var message = `Specimen ID ${batchSpecimen.specimenId} was found on archived batch ${batch.name} but could not be found in archived specimens`;
                    alert(message);
                    console.error(message);
               }
            }
        })
   })

    return result;
};

export const MapPatientsToBatches = (batches: ArchivedBatch[], patients: ArchivedPatient[]): Map<ArchivedBatch, ArchivedPatient[]> => {
    var result = new Map<ArchivedBatch, ArchivedPatient[]>();

    batches.forEach(batch => {

        let batchPatients: ArchivedPatient[] = [];
        let regex = new RegExp("LU[ABCDEFGHJKLMNPQRTUVWXYZ2346789]{6}");

        batch.specimens.forEach(batchSpecimen => {
            if (regex.test(batchSpecimen.specimenId)) {
                let patient = patients.find(patient => patient.sampleId === batchSpecimen.specimenId);
                if (patient) {
                    batchPatients.push(patient!);
                    result.set(batch, batchPatients);
                } else {
                    var message = `Sample ID ${batchSpecimen.specimenId} was found on archived batch ${batch.name} but could not be found in archived Lumi patients`;
                    alert(message);
                    console.error(message);
                }
            }
        })
   })

    return result;
};

