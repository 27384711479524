import { OuterWrapper, ContentWrapper, PageLayoutWrapper } from './styles';
import { useContext } from 'react';
import { LayoutContext } from '../../Context/Layout/LayoutContext';
import LayoutContextType from '../../Models/LayoutContextType';

const PageWrapper = (props: any) => {
    var layoutContext = useContext(LayoutContext) as LayoutContextType;

    return (
        <OuterWrapper style={{
            // Adding these inline styles because the navbar is static and absolutely positioned
            left: layoutContext.navBarWidth, 
            width: `calc(100vw - ${layoutContext.navBarWidth}px)`}}
        >
            <PageLayoutWrapper>
                <ContentWrapper>
                    {props.children}
                </ContentWrapper>
            </PageLayoutWrapper>
        </OuterWrapper>
    );
};

export default PageWrapper;