import BatchReportData from '../../Models/BatchReportData';

interface PrintBatchTableProps {
    data: BatchReportData[]
}

const PrintBatchTable = ({ data }: PrintBatchTableProps): JSX.Element => {

    const rows = (data: BatchReportData[]): JSX.Element[] => {

        var result = data.map((batchReport, index) => {

            if (!batchReport.specimen) {
                return (
                    <tr key={`print-batch-row-${index}`}>
                        <td>{batchReport.wellNumber}</td>
                        <td>{batchReport.wellType}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )
            } 

            var specimen = batchReport.specimen;

            return (
                <tr key={`print-batch-row-${index}`}>
                    <td>{batchReport.wellNumber}</td>
                    <td>{specimen.caseId}</td>
                    <td>{`${specimen.firstName} ${specimen.lastName}`}</td>
                    <td>{specimen.dateOfBirth.toString()}</td>
                    <td>{specimen.comment}</td>
                </tr>
            )
        });

        return result;
    };

    return (
            <table style={{border: '1px solid', borderCollapse: 'collapse'}}>
                <thead>
                    <tr style={{textAlign: 'left'}}>
                        <th>Well Number</th>
                        <th>Case ID</th>
                        <th>Patient Name</th>
                        <th>Patient DOB</th>
                        <th style={{width: "50%"}}>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {rows(data)}
                </tbody>
            </table>
    )
};

export default PrintBatchTable;