import PanelData from "../../Models/PanelData";
import { GridWrapper, WellColumn } from "./styles";
import WellData from '../../Models/WellData';
import Well from '../../Components/Well';
import { WellEntryData } from "../../Models/WellEntryData";

interface WellGridProps {
    panelData: PanelData,
    focusedWellIndex: number,
    onFillWell:(barcode: string, wellNumber: string) => WellEntryData,
    onWellFocus:(index: number) => void,
    onWellRemove:(wellNumber: string) => void
}

const WellGrid = ({panelData, focusedWellIndex, onFillWell, onWellFocus, onWellRemove}: WellGridProps) => {

    // The well data should be stored in order of display: A1 -> A12... 

    const rows: JSX.Element[] = [];
    var wellIndexOffset = 0;
    
    for (var i = 0; i < panelData.height; i++) {
        let wells: WellData[] = [];

        for (let j = 0; j < panelData.width; j++) {
            var well: WellData = panelData.wells[wellIndexOffset + j];
            wells.push(well);
        }

        rows.push(
            <WellColumn key={`wellgrid-row-${i}`}>
                {
                    wells.map((well, index) => {

                        var wellIndex = wellIndexOffset + index;

                        var isFocused = wellIndex === focusedWellIndex;

                        return (
                        <Well 
                            data={well} 
                            key={`wellgrid-well-${index}`} 
                            focused={isFocused} 
                            onFill={onFillWell}
                            onFocus={onWellFocus}
                            onRemove={onWellRemove}
                            index={wellIndex}
                        />);
                    })
                }
            </WellColumn>
        );

        wellIndexOffset += panelData.width;
    }

    return (
        <GridWrapper>
            {rows}
        </GridWrapper>
    )
}

export default WellGrid;