import React, { useEffect, useState } from "react";
import { ContentWrapper, SectionWrapper } from "../CreateBatchTab/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ArchivedBatch from "../../../../Models/ArchivedBatch";
import ArchivedSpecimen from "../../../../Models/ArchivedSpecimen";
import ArchivedPatient from "../../../../Models/ArchivedPatient";
import { GetArchivedBatches, GetArchivedSpecimens, GetArchivedPatients } from "../../../../Graphql/Api";
import { CircularProgress, Divider } from "@mui/material";
import { SpinnerWrapper } from "./styles";
import { MapSpecimensToBatches, MapPatientsToBatches } from './utils';
import ExpandableBatchRow from './Components/ExpandableBatchRow';
import { AppModeConfig } from "../../../../AppModes/AppModeConfig";

interface ArchivedBatchesTabProps {
    preselectedBatchName: string,
    appModeConfig: AppModeConfig
} 

const ArchivedBatchesTab = ({ preselectedBatchName, appModeConfig }: ArchivedBatchesTabProps) => {
    const [archivedBatches, setArchivedBatches] = useState<ArchivedBatch[] | null>(null);
    const [archivedSpecimens, setArchivedSpecimens] = useState<ArchivedSpecimen[] | null>(null);
    const [archivedPatients, setArchivedPatients] = useState<ArchivedPatient[] | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(archivedBatches === null || archivedSpecimens === null || archivedPatients === null);

    useEffect(() => {

        const fetchArchivedData = async () => {
            setArchivedBatches(await GetArchivedBatches());
            setArchivedSpecimens(await GetArchivedSpecimens());
            setArchivedPatients(await GetArchivedPatients());
        };

        fetchArchivedData()
        .then(() => {
            setIsLoading(false);
        })
        .catch(error => {
            alert("There was an error fetching archived data");
            console.error(error);
        })
        
    }, []);

    const archivedBatchContent = () => {

        var batchSpecimenMap = MapSpecimensToBatches(archivedBatches!, archivedSpecimens!);
        var batchPatientMap = MapPatientsToBatches(archivedBatches!, archivedPatients!);

        var rows: JSX.Element[] = [];
        
        batchSpecimenMap.forEach((value, key) => { 
            rows.push(
                <ExpandableBatchRow 
                    batch={key} 
                    specimens={value} 
                    patients={[]}
                    key={`batch-row-${key.name}`} 
                    isSelected={key.name === preselectedBatchName}
                    appModeConfig={appModeConfig}
                />
            )
        });

        batchPatientMap.forEach((value, key) => { 
            rows.push(
                <ExpandableBatchRow 
                    batch={key} 
                    specimens={[]} 
                    patients={value}
                    key={`batch-row-${key.name}`} 
                    isSelected={key.name === preselectedBatchName}
                    appModeConfig={appModeConfig}
                />
            )
        });

        return (
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Batch name</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Archived By</TableCell>
                    <TableCell>Date Archived</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
                </Table>
            </TableContainer>
        )
    };

    return (
        <>
            {!isLoading &&
                <ContentWrapper>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h4" component="div">
                            Archived Batches
                        </Typography>
                    </SectionWrapper>
                    <Divider variant="middle" />
                    <SectionWrapper>
                        {archivedBatchContent()}
                    </SectionWrapper>
                </ContentWrapper>}
            {isLoading &&
                <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>}
        </>
    );
}

export default ArchivedBatchesTab;