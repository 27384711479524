import { 
    ContentWrapper, 
    SectionWrapper, 
    StickyFooter, 
    Buffer as PageBuffer, 
    BatchProgressTitle, 
    SideBySide, 
    BatchProgressWindow, 
    ChipWrapper,
    ModalStyle, 
    SpinnerWrapper} from './styles';
import PanelData from '../../../../Models/PanelData';
import react, { useContext, useEffect, useState } from 'react';
import { 
    AddSpecimenToBatch, 
    GroupSpecimensByCaseId, 
    InitializeNewBatch, 
    InitializeNewPanel, 
    RemoveSpecimenFromBatchByWellNumber, 
    UpdateBatchName, 
    UpdateBatchRackBarcode, 
    UpdateBatchPlateBarcode, 
    UpdateBatchTube2DBarcodesFilename,
    UpdateBatchProgress, 
    ValidateBatch
} from './utils';
import { Divider, TextField, Typography, Button, CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import WellGrid from '../../../../Components/WellGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Batch from '../../../../Models/Batch';
import { AuthContext } from '../../../../Context/Auth/AuthContext';
import Specimen from '../../../../Models/Specimen';
import { GetArchivedBatches, UpsertBatches, UpsertTube2DBarcodes } from '../../../../Graphql/Api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useDispatch } from "react-redux";
import { addBatch } from '../../../../ReduxSlices/batchSlice';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import UpdateBatchProgessAction from '../../../../Models/Constants/UpdateBatchProgressAction';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from 'react';
import ArchivedBatch from '../../../../Models/ArchivedBatch';
import CSVReader from "react-csv-reader";
import Tube2DBarcodes from '../../../../Models/Tube2DBarcodes';
import BatchTube2DBarcodes from "../../../../Models/BatchTube2DBarcodes";
import { AppModeConfig } from '../../../../AppModes/AppModeConfig';
import { CreateBatchScreenFields } from '../../../../Models/AppConfig/CreateBatchScreenFields';
import { WellEntryData } from '../../../../Models/WellEntryData';
  
interface CreateBatchTabProps {
    onStateUpdate: () => void,
    onNavigateToViewBatch: (batch: Batch) => void,
    appModeConfig: AppModeConfig
}

const CreateBatchTab = ({ onStateUpdate, onNavigateToViewBatch, appModeConfig }: CreateBatchTabProps) => {

    var dispatch = useDispatch();
    const batchState = useSelector((state: RootState) => state.batches.batches);
    const specimenState = useSelector((state: RootState) => state.specimens.specimens);
    const patientState = useSelector((state: RootState) => state.patients.patients);

    const authContext = useContext(AuthContext);

    const [archivedBatches, setArchivedBatches] = useState<ArchivedBatch[]>([]);
    const [focussedWellIndex, setFocussedWellIndex] = useState<number>(-1);
    const [currentBatch, setCurrentBatch] = useState<Batch | null>();
    const [batchProgress, setBatchProgress] = useState<Specimen[]>([]);

    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [confirmationTextElement, setConfirmationTextElement] = useState<JSX.Element>(<></>);
    
    const [batchValid, setBatchValid] = useState<boolean>(true);
    const [batchWarning, setBatchWarning] = useState<boolean>(true);

    const panelData: PanelData = InitializeNewPanel(appModeConfig.BatchConfig.defaultPanelConfig);

    const specimensGroupedByCaseId = GroupSpecimensByCaseId(specimenState);

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [csvRows, setCsvRows] = useState<any[]>([]);

    useEffect(() => {
        // Fetch archived batches as all historical data is required when creating a new batch
        var FetchArchivedBatches = async () => {
            return await GetArchivedBatches();
        }

        FetchArchivedBatches()
        .then(archivedBatches => {

            setArchivedBatches(archivedBatches);

            var historicalBatchData: Batch[] = [...archivedBatches, ...batchState];

            setCurrentBatch(InitializeNewBatch(authContext?.user?.getUsername()!, historicalBatchData));
            setIsLoading(false);
        })
        .catch(error => {
            setToastError("Error occurred fetching archived batches");
            console.error("Error occurred fetching archived batches - " + error)
            setToastOpen(true);
        })

    }, [authContext, batchState]);

    const handleBatchNameChange = (event: react.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentBatch(UpdateBatchName(currentBatch!, event.target.value));
    };

    const handleBatchRackBarcodeChange = (event: react.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentBatch(UpdateBatchRackBarcode(currentBatch!, event.target.value));
    };

    const handleBatchPlateBarcodeChange = (event: react.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentBatch(UpdateBatchPlateBarcode(currentBatch!, event.target.value));
    };

    const importTube2DBarcodesAsync = async () => {
        var tube2DBarcodes: Tube2DBarcodes =
            {
                batchName: currentBatch?.name ?? "",
                barcodes: []
            };

            csvRows.forEach(row => {
                var batchTube2DBarcode: BatchTube2DBarcodes = {
                    wellNumber: row[0],
                    barcode: row[1]
                }

                // Filter out any empty entries
                if (batchTube2DBarcode.wellNumber && batchTube2DBarcode.barcode) {
                     tube2DBarcodes.barcodes.push(batchTube2DBarcode);
                }
            });

        await UpsertTube2DBarcodes(tube2DBarcodes);
    };

    const handleCsvSelection = (data: any[], fileInfo: any, originalFile: File | undefined) => {
        setCurrentBatch(UpdateBatchTube2DBarcodesFilename(currentBatch!, originalFile!.name));
        setCsvRows(data);
    };

    const isIdInBatch = (id: string, batch: Batch) => {
        var exists = batch.specimens.find(specimen => specimen.specimenId === id);
        return exists !== undefined;
    }

    // Return value is whether or not specimen entry is valid
    const handleFillWell = (barcode: string, wellNumber: string): WellEntryData => {
        
        var wellEntryData: WellEntryData = {
            name: "",
            id: "",
            valid: false
        };

        var specimen = specimenState.find(specimen => specimen.barcode === barcode);
        var patient = patientState.find(patient => patient.sampleId === barcode && patient.sampleErrorNote === "");

        var specimenId = "";

        if (specimen) {
            wellEntryData = {
                name: `${specimen.lastName} ${specimen.firstName.slice(0, 1)}`.toUpperCase(),
                id: specimen.specimenId,
                valid: true
            };

            specimenId = specimen.specimenId;
        }

        if (patient) {
            wellEntryData = {
                name: `${patient.lastName} ${patient.firstName.slice(0, 1)}`.toUpperCase(),
                id: patient.sampleId,
                valid: true
            };

            specimenId = patient.sampleId;
        }

        if (!specimen && !patient) return wellEntryData;

        if (isIdInBatch(specimenId, currentBatch!)) {
            setToastError("Specimen already exists in this batch!");
            setToastOpen(true);
            wellEntryData.valid = false;
            return wellEntryData;
        }

        setCurrentBatch(AddSpecimenToBatch(currentBatch!, specimenId, wellNumber));

        // Move focus to next well
        if (wellEntryData.valid) setFocussedWellIndex(focussedWellIndex + 1);

        return wellEntryData;
    };

    const handleWellFocus = (index: number) => {
        setFocussedWellIndex(index);
    };

    const handleWellRemove = (wellNumber: string) => {
        // Get specimen by well Number
        var specimenId = currentBatch!.specimens.find(x => x.wellNumber === wellNumber)?.specimenId;

        if (!specimenId) {
            setToastError("Attempting to remove a specimen that doesn't exist in the batch | Well number: " + wellNumber);
            setToastOpen(true);
            return;
        }

        var specimen = specimenState.find(x => x.specimenId === specimenId);

        // Remove specimen from current batch
        setCurrentBatch(RemoveSpecimenFromBatchByWellNumber(currentBatch!, wellNumber));

        // Update Batch Progress
        var updatedProgress = UpdateBatchProgress(
            UpdateBatchProgessAction.REMOVE, 
            currentBatch!, 
            batchProgress, 
            specimen!, 
            specimensGroupedByCaseId
        );
        // Have to do this workaround to get React to rerender after the setState
        setBatchProgress([...updatedProgress]);
    }

    const toggleModal = () => {
        setOpenConfirmation(openConfirmation => !openConfirmation);
    }

    const handleSubmitButtonClicked = () => {
        var historicalBatchData: Batch[] = [...archivedBatches, ...batchState];
        var validationResult = ValidateBatch(currentBatch!, historicalBatchData, batchProgress, appModeConfig);

        setBatchValid(validationResult.isValid);
        setBatchWarning(validationResult.displayWarning);

        var confirmationTextElement = <p>Are you sure you want to submit this batch?</p>;
        
        if (validationResult.validationErrorMessages.length > 0)
        {
            var errorMessageElement = validationResult.validationErrorMessages.map(message => <>{message}<br/><br/></>);
            confirmationTextElement = <>{errorMessageElement}</>;
        }

        setConfirmationTextElement(confirmationTextElement)
        setOpenConfirmation(true);
    }

    const handleSubmitBatch = () => {
        setOpenConfirmation(false);

        importTube2DBarcodesAsync();

        UpsertBatches(currentBatch!)
        .then(result => {
            if (result.isSuccess) {
                // add batch to state
                dispatch(addBatch(currentBatch!));
                // navigate to view batches page
                toggleModal();
            } else {
                setToastError("Error occurred submitting batch");
                console.error("LimsApi returned unsuccessful response");
            }

            setToastError("");
            onStateUpdate();
            onNavigateToViewBatch(currentBatch!);
        })
        .catch(error => {
            setToastError("Error occurred submitting batch - " + error.errors[0].message);
            console.error("Error occurred submitting batch - " + error.errors[0].message);
        })

        setToastOpen(true);
    }

    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setToastOpen(false);
    }

    const batchProgressContent = (batchProgress: Specimen[]): JSX.Element[] => 
        batchProgress.map((specimen, index) => {

            var specimenDetails = `${specimen.lastName.toUpperCase()} ${specimen.firstName.slice(0,1).toUpperCase()} - ${specimen.specimenId}`;

            return (
            <ChipWrapper key={`chip-${index}`}>
                <Chip label={specimenDetails}/>
            </ChipWrapper>
            )}
        );

    // Render fields dependent on app mode
    const optionalFields = () => {

        var fields: JSX.Element[] = [];

        if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.RACK)) {
            fields.push(
            <SectionWrapper key={'rack-input'}>
                <Typography gutterBottom variant="h6" component="span">
                    Rack barcode:{" "}
                </Typography>
                <TextField 
                    id="standard-basic" 
                    label="" 
                    variant="standard" 
                    defaultValue={currentBatch!.rackBarcode} 
                    onChange={handleBatchRackBarcodeChange}
                />
            </SectionWrapper>);
        }

        if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.PLATE)) {
            fields.push(
                <SectionWrapper key={'plate-input'}>
                    <Typography gutterBottom variant="h6" component="span">
                        Plate barcode:{" "}
                    </Typography>
                    <TextField 
                        id="standard-basic" 
                        label="" 
                        variant="standard" 
                        defaultValue={currentBatch!.plateBarcode} 
                        onChange={handleBatchPlateBarcodeChange}
                    />
                </SectionWrapper>
            );
        }

        if (appModeConfig.BatchConfig.requiredFields.includes(CreateBatchScreenFields.TUBE2DBARCODE)) {
            fields.push(
                <div key={'tube-2d-barcode-input'}>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                            Tube 2D barcode file:{" "}
                        </Typography>
                        <CSVReader onFileLoaded={handleCsvSelection} />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </div>
            );
        }

        return fields;
    }

    return (
        <>
            {isLoading &&
            <SpinnerWrapper>
                <CircularProgress />
            </SpinnerWrapper>}
            {!isLoading &&
            <ContentWrapper>
                <>
                    <SectionWrapper>
                        <Typography gutterBottom variant="h4" component="div">
                            Create a Batch
                        </Typography>
                        <Button onClick={handleSubmitButtonClicked} variant="outlined">Submit Batch</Button>
                    </SectionWrapper>
                    <Divider variant="middle" />
                    <SectionWrapper>
                        <Typography gutterBottom variant="h6" component="span">
                            Batch name:{" "}
                        </Typography>
                        <TextField 
                            id="standard-basic" 
                            label="" 
                            variant="standard" 
                            defaultValue={currentBatch!.name} 
                            onChange={handleBatchNameChange}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                    {optionalFields()}
                    <SectionWrapper>    
                        <WellGrid 
                            panelData={panelData} 
                            focusedWellIndex={focussedWellIndex} 
                            onFillWell={handleFillWell} 
                            onWellFocus={handleWellFocus}
                            onWellRemove={handleWellRemove}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                    <SectionWrapper>
                        <Button onClick={handleSubmitButtonClicked} variant="outlined">Submit Batch</Button>
                    </SectionWrapper>
                    <PageBuffer />
                    <StickyFooter>
                        <SideBySide>
                            <BatchProgressTitle>
                                <Typography gutterBottom variant="h6" component="span">
                                    Batch Progress:
                                </Typography>
                            </BatchProgressTitle>
                            <BatchProgressWindow>
                                {batchProgressContent(batchProgress)}
                            </BatchProgressWindow>
                        </SideBySide>
                    </StickyFooter>
                    <Modal
                        open={openConfirmation}
                        onClose={() => toggleModal()}
                    >
                        <Box sx={ModalStyle}>
                            {!batchValid && 
                            <DangerousIcon />}
                            {batchWarning &&
                            <WarningIcon />}
                            <Typography variant="h6" component="h2">
                                {confirmationTextElement}
                            </Typography>
                            {batchValid &&
                            <Button color="success" variant="contained" onClick={handleSubmitBatch} sx={{margin: '0 2rem'}}>SUBMIT</Button>}
                            <Button color="error" variant="contained" onClick={toggleModal}>CANCEL</Button>
                        </Box>
                    </Modal>
                    <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                        <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                            {toastError === "" ? "Success!" : toastError}
                        </MuiAlert>
                    </Snackbar>
                </>
            </ContentWrapper>}
        </>
    );
}

export default CreateBatchTab;
