import { Typography, Divider, FormControl, Box, InputLabel, MenuItem, Select, SelectChangeEvent, Button, Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import { ContentWrapper, SectionWrapper, SpinnerWrapper } from './styles';
import ApproverOnlyTestComponent from './Components/ApproverOnlyTest';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import BatchAttribute from "../../Models/BatchAttribute";
import Batch from "../../Models/Batch";
import { UpsertBatches } from "../../Graphql/Api";
import { updateBatch } from "../../ReduxSlices/batchSlice";
import CircularProgress from '@mui/material/CircularProgress';
import TestType from "../../Models/TestType";
import ApproverReagentTestComponent from "./Components/ApproverReagentTest";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {PunchingReagents, ExtractionReagents, PCRReagents, FragmentAnalysisReagents} from '../../Models/Constants/ReagentConstants';
import { ModalStyle } from "../Batches/Tabs/CreateBatchTab/styles";
import WarningIcon from '@mui/icons-material/Warning';
import { ReturnAppModeConfig } from "../../AppModes/AppModeConfig";
import { AppModeContext } from "../../Context/AppMode/AppModeContext";
import AppModeContextType from "../../Models/AppModeContextType";


const TestingPage = () => {
    const dispatch = useDispatch();

    const batchState = useSelector((state: RootState) => state.batches.batches);
    const [selectedBatch, setSelectedBatch] = useState<Batch | undefined>(undefined);

    const appModeContext = useContext(AppModeContext) as AppModeContextType;
    
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalTextElement, setModalTextElement] = useState<JSX.Element>(<></>);

    const [overwritingAttribute, setOverwritingAttribute] = useState<BatchAttribute | null>(null);
    
    const appModeConfig = ReturnAppModeConfig(appModeContext.mode);

    const handleSelectBatch = (event: SelectChangeEvent<string>) => {
        var batchName = event.target.value;
        setSelectedBatch(batchState.find(x => x.name === batchName));
    };

    const batchSelectorContent = () => {
        var menuItems = batchState.map((batch, index) => {
            return <MenuItem value={batch.name} key={`batch-${index}`}>{batch.name}</MenuItem>
        });

        return (
            <>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="batch-selector-input-label">Batches</InputLabel>
                        <Select
                            labelId="batch-selector-select-label"
                            id="batch-selector-selector"
                            value={selectedBatch?.name ?? ""}
                            label="Batches"
                            onChange={handleSelectBatch}
                        >
                            {menuItems}
                        </Select>
                    </FormControl>
                </Box>
            </>
        );
    }

    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
      
        setToastOpen(false);
    }

    const toggleModal = () => {
        setModalOpen(modalOpen => !modalOpen);
    }

    const handleTestSubmit = async (attribute: BatchAttribute) => {
        setIsLoading(true);
        setModalOpen(false);
        setModalTextElement(<></>);

        // Clone attributes array to make it mutatable
        var resultAttributes = [...selectedBatch!.attributes];

        // Validate if attribute already exists
        var existingAttributeIndex = resultAttributes.findIndex(attr => attr.type === attribute.type)
        if (existingAttributeIndex !== -1) {
            // Replace it if exists
            resultAttributes.splice(existingAttributeIndex, 1, attribute);
        } else {
            resultAttributes.push(attribute);
        }

        var result: Batch = {
            ...selectedBatch!,
            attributes: resultAttributes
        };

        // Send request to graphql
        var upsertResponse = await UpsertBatches(result);

        if (!upsertResponse.isSuccess) {
            
            var message = "Upserting batch " + selectedBatch!.name + " was unsuccessful";
            setToastError(message);
            console.error(message);
            setIsLoading(false);
            setToastOpen(true);
            return;
        }

        // Update batch in application and local state
        dispatch(updateBatch(result));
        setSelectedBatch(result);

        setIsLoading(false);

        setToastError("");
        setToastOpen(true);
    };

    const onSubmitClick = async (attribute: BatchAttribute) => {

        if (!selectedBatch) return;

        // Validate whether this test has already been submitted, display warning confirmation if so
        var existingAttribute = selectedBatch.attributes.find(att => att.type === attribute.type);
        if (existingAttribute) {
            setOverwritingAttribute(attribute);
            setModalTextElement(
            <>
                This test has been submitted before:<br />
                Approver: {existingAttribute!.approver}<br />
                Submitted at: {existingAttribute?.dateCreated.toString()}<br />
                Do you want to overwrite this?
            </>);
            setModalOpen(true);
        } else {
            setModalOpen(false);
            handleTestSubmit(attribute);
        }
    }

    const optionalTests = () => {
        var tests: JSX.Element[] = [];

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.PUNCHING)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverReagentTestComponent 
                            name="Punching"
                            type={TestType.PUNCHING}
                            onSubmit={onSubmitClick}   
                            reagents={PunchingReagents}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.PCR)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverReagentTestComponent 
                            name="PCR"
                            type={TestType.PCR}
                            onSubmit={onSubmitClick}
                            reagents={PCRReagents}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.FRAGMENTANALYSIS)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverReagentTestComponent 
                            name="Fragment Analysis"
                            type={TestType.FRAGMENTANALYSIS}
                            onSubmit={onSubmitClick}
                            reagents={FragmentAnalysisReagents}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.DATAANALYSIS)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverOnlyTestComponent 
                            name="Data Analysis"
                            type={TestType.DATAANALYSIS}
                            onSubmit={onSubmitClick}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.DATACHECK)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverOnlyTestComponent 
                            name="Data Check"
                            type={TestType.DATACHECK}
                            onSubmit={onSubmitClick}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.DIRECTORREVIEW)) {
            tests.push(
                <>
                    <SectionWrapper>
                        <ApproverOnlyTestComponent 
                            name="Director Review"
                            type={TestType.DIRECTORREVIEW}
                            onSubmit={onSubmitClick}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.RESULTSDELIVERY)) {
            tests.push(
                <>
                   <SectionWrapper>
                        <ApproverOnlyTestComponent 
                            name="Results Delivery"
                            type={TestType.RESULTSDELIVERY}
                            onSubmit={onSubmitClick}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        if (appModeConfig?.BatchConfig.testTypes.includes(TestType.EXTRACTION)) {
            tests.push(
                <>
                   <SectionWrapper>
                      <ApproverReagentTestComponent 
                            name="Extraction"
                            type={TestType.EXTRACTION}
                            onSubmit={onSubmitClick}   
                            reagents={ExtractionReagents}
                        />
                    </SectionWrapper>
                    <Divider variant="middle" />
                </>
            );
        }

        return tests;
    }

    const testInputContent = () => {

        return (
            <>
                <SectionWrapper>
                    <Typography gutterBottom variant="h6" component="div">
                        {selectedBatch!.name}
                    </Typography>
                </SectionWrapper>
                <Divider variant="middle" />
                {!isLoading &&
                <>
                    {optionalTests()}
                    <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                        <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                            {toastError === "" ? "Success!" : toastError}
                        </MuiAlert>
                    </Snackbar>
                </>}
                {isLoading &&
                <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>}
            </>
        )
    } 

    const content = selectedBatch === undefined ? batchSelectorContent() : testInputContent();

    return (
        <ContentWrapper>
            <SectionWrapper>
                <Typography gutterBottom variant="h4" component="div">
                    Testing
                </Typography>
            </SectionWrapper>
            <Divider variant="middle" />
            {content}
            <Modal
                open={modalOpen}
                onClose={() => toggleModal()}
            >
                <Box sx={{...ModalStyle, width: "40rem"}}>
                    <WarningIcon />
                    <Typography variant="h6" component="h2">
                        {modalTextElement}
                    </Typography>
                    <Button color="success" variant="contained" onClick={() => handleTestSubmit(overwritingAttribute!)} sx={{margin: '0 2rem'}}>SUBMIT TEST</Button>
                    <Button color="error" variant="contained" onClick={toggleModal}>CANCEL</Button>
                </Box>
            </Modal>
        </ContentWrapper>
    )
};

export default TestingPage;