import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableWrapper, CriticalRow, RegularRow, NumberOfRows } from './styles';
import Patient from '../../../../../../Models/Patient';
import { IsOutstandingPatientCritical } from '../../../../utils';

interface OutstandingPatientsTableProps {
    outstandingPatients: Patient[];
}

export const OutstandingPatientsTable = ({ outstandingPatients }: OutstandingPatientsTableProps) => {

    const tableHeaders = [
        "Sample ID",
        "Sample name",
        "Date received",
        "Created by"
    ];

    const DetermineCriticalOutstandingPatients = (outstandingPatients: Patient[]): [criticalPatients: Patient[], regularOutstandingPatients: Patient[]] => {
        var regular: Patient[] = [];
        var critical: Patient[] = [];

        outstandingPatients.forEach(patient => {
            if (IsOutstandingPatientCritical(patient)) {
                critical.push(patient);
            } else {
                regular.push(patient);
            }
        });

        return [ critical, regular ];
    }

    const GenerateTableRows = (outstandingPatients: Patient[]): JSX.Element[] => {
        var [critical, regular] = DetermineCriticalOutstandingPatients(outstandingPatients)
    
        var rows: JSX.Element[] = [];

        critical.sort(SortByDateCreated);
        regular.sort(SortByDateCreated);

        critical.forEach((critical, index) => {
            var dateCreated = new Date(critical.dateCreated);
            rows.push (
                <CriticalRow key={`critical-row-${index}`}>
                    <TableCell>{critical.sampleId}</TableCell>
                    <TableCell>{`${critical.firstName} ${critical.lastName}`}</TableCell>
                    <TableCell>{`${dateCreated.toLocaleDateString('en-AU')} ${dateCreated.toLocaleTimeString('en-AU')}`}</TableCell>
                    <TableCell>{critical.createdBy}</TableCell>
                </CriticalRow>
            )
        });

        regular.forEach((regular, index) => {
            var dateCreated = new Date(regular.dateCreated);
            rows.push(
                <RegularRow key={`regular-row-${index}`}>
                    <TableCell>{regular.sampleId}</TableCell>
                    <TableCell>{`${regular.firstName} ${regular.lastName}`}</TableCell>
                    <TableCell>{`${dateCreated.toLocaleDateString('en-AU')} ${dateCreated.toLocaleTimeString('en-AU')}`}</TableCell>
                    <TableCell>{regular.createdBy}</TableCell>
                </RegularRow>
            );
        });

        return rows;
    }

    const SortByDateCreated = (a: Patient, b: Patient): number => {
        if (a.dateCreated < b.dateCreated) return -1; // a should be first
        if (a.dateCreated === b.dateCreated) return 0; // both the same
        return 1; // b should be first
    };

    const rows = GenerateTableRows(outstandingPatients);

    return (
        <TableWrapper>
            <NumberOfRows>
                {`Number of rows: ${rows.length}`}
            </NumberOfRows>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        {tableHeaders.map((header, index) => <TableCell key={`header-${index}`}>{header}</TableCell>)}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </TableWrapper>
    );

}


