import PanelConfigType from "../PanelConfigType";

export const KinshipPanelConfig: PanelConfigType = {
    width: 8,
    height: 12,
    ladderStartingWell: "A1",
    ladderFrequency: 24,
    controlWells: {
        positiveControl: "B1",
        negativeControl: "C1"
    }
};

export const CarrierScreeningPanelConfig: PanelConfigType = {
    width: 8,
    height: 12
};