import CustomReagentAttributes from '../Models/CustomReagentAttributes';
import BatchAttribute from '../Models/BatchAttribute';
import React from 'react';
import { Chip } from '@mui/material';
import styled from 'styled-components';

export const ListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
`;

export const ChipWrapper = styled.div`
    display: flex;
    width: 100%;

    padding: 0.5rem 0;
`;

const GenerateExtractionTestTypeDisplay = (attribute: BatchAttribute) => {
    var extractionAttribute: CustomReagentAttributes = JSON.parse(attribute.customAttributes ?? "");

    if (!extractionAttribute || !extractionAttribute.reagents) return <></>;

    return (
        <ListWrapper>
            Extractions
            {extractionAttribute.reagents.map((extraction, index) => 
                <ChipWrapper key={`value-chip-wrapper-${index}`}>
                    <Chip 
                        key={`value-chip-${index}-lotnumber`}
                        label={`LOT: ${extraction.lotNumber}`}
                    />
                    <Chip 
                        key={`value-chip-${index}-expiry`}
                        label={`expiry date: ${extraction.expiry}`}
                    />
                </ChipWrapper>
            )}
        </ListWrapper>
    );
}

export default GenerateExtractionTestTypeDisplay;