import { useState, useContext } from 'react';
import { FormControl, Box, InputLabel, Input, Button, FormGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/Auth/AuthContext';
import AuthContextType from '../../Models/AuthContextType';
import { CenterWrapper } from './styles';

const LoginPage = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext) as AuthContextType;

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const onSubmit = () => {
        authContext.login(username, password)
        .then((user) => {
            authContext.setUser(user);
            navigate("/")
        })
        .catch(err =>
            alert("Invalid username or password")
        );;
    };

    return (
        <CenterWrapper>
            <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '40rem'}}>
                <FormGroup>
                    <FormControl sx={{padding: '1rem'}}>
                        <InputLabel>Username</InputLabel>
                        <Input id="username" onChange={event => setUsername(event.target.value)}/>
                    </FormControl>
                    <FormControl sx={{padding: '1rem'}}>
                        <InputLabel>Password</InputLabel>
                        <Input id="password" type="password" onChange={event => setPassword(event.target.value)}/>
                    </FormControl>
                    <FormGroup sx={{padding: '1.5rem 0.5rem'}}>
                        <FormControl>
                            <Button type="submit" variant="outlined" onClick={onSubmit}>Submit</Button>
                        </FormControl>
                    </FormGroup>
                </FormGroup>
            </Box>
        </CenterWrapper>
    )
};

export default LoginPage;