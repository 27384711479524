import React, { ChangeEvent, useState } from "react";
import { Row, ThirdWrapper } from "./styles";
import { TextField } from "@mui/material";
import ReagentData from "../../Models/ReagentData";

interface ReagentEntryRowProps {
    reagent: string,
    onUpdate: (data: ReagentData) => void
}

const ReagentEntryRow = ({ reagent, onUpdate }: ReagentEntryRowProps) => {
    const [data, setData] = useState<ReagentData>(({name: reagent, lotNumber: "", expiry: ""} as ReagentData));

    const handleLotNumberInput = (event: ChangeEvent<HTMLInputElement>) => {
        var newData = {...data, lotNumber: event.target.value};
        onUpdate(newData);
        setData(newData);
    }

    const handleExpiryInput = (event: ChangeEvent<HTMLInputElement>) => {
        var newData = {...data, expiry: event.target.value};
        onUpdate(newData);
        setData(newData);
    }

    return (
        <Row>
            <ThirdWrapper>
                {reagent}
            </ThirdWrapper>
            <ThirdWrapper>
                <TextField 
                    id="reagent-lot-number-input"
                    variant="standard" 
                    onInput={handleLotNumberInput}
                    value={data.lotNumber ?? ""}
                />
            </ThirdWrapper>
            <ThirdWrapper>
                <TextField 
                    id="reagent-expiry-input"
                    variant="standard" 
                    onInput={handleExpiryInput}
                    value={data.expiry}
                />
            </ThirdWrapper>
        </Row>
    )
}

export default ReagentEntryRow;