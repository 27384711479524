import { createContext, useState } from 'react';
import AppModeContextType from '../../Models/AppModeContextType';
import { AppModes } from '../../Models/Constants/AppModes';

export const AppModeContext = createContext<AppModeContextType | null>(null);

export const AppModeContextProvider = (props: any) => {

    const defaultAppMode = AppModes.KINSHIP;

    const [appMode, setAppMode] = useState<AppModes>(defaultAppMode);

    return (
        <AppModeContext.Provider value={{
            mode: appMode,
            setAppMode: setAppMode
        }} >
            {props.children}
        </AppModeContext.Provider>
    );
}
