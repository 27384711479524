import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/Auth/AuthContext';
import AuthContextType from '../../Models/AuthContextType';
import { LayoutContext } from '../../Context/Layout/LayoutContext';
import LayoutContextType from '../../Models/LayoutContextType';
import { AppModeContext } from '../../Context/AppMode/AppModeContext';
import AppModeContextType from '../../Models/AppModeContextType';
import { AppModes } from '../../Models/Constants/AppModes';

import { NavBarOption } from '../../Models/NavBarOption';
import { KinshipModeConfig } from '../../AppModes/KinshipModeConfig';
import { CarrierScreeningModeConfig } from '../../AppModes/CarrierScreeningModeConfig';

const NavBar = () => {

    var authContext = useContext(AuthContext) as AuthContextType;
    var layoutContext = useContext(LayoutContext) as LayoutContextType;
    var appModeContext = useContext(AppModeContext) as AppModeContextType;

    var navigate = useNavigate();

    var [userLoggedIn, setIsUserLoggedIn] = useState<boolean>(authContext.user !== null);
    var [navOptions, setNavOptions] = useState<NavBarOption[]>([]);

    useEffect(() => {
        setIsUserLoggedIn(authContext.user !== null);

        switch(appModeContext.mode) {
            case AppModes.KINSHIP:
                setNavOptions(KinshipModeConfig.NavBarOptions);
                break;
            case AppModes.CARRIERSCREENING:
                setNavOptions(CarrierScreeningModeConfig.NavBarOptions);
                break;
            default:
                console.error("Unknown app mode: " + appModeContext.mode);
                break;
        }


    }, [authContext, appModeContext]);

    const handleRedirect = (destination: string) => {
        navigate(destination);
    }

    const content = userLoggedIn ? 
        navOptions.map((option) => (
            <ListItem key={option.name}>
                <ListItemButton onClick={() => handleRedirect(option.redirect)}>
                    <ListItemIcon>
                        {option.icon}
                    </ListItemIcon>
                    <ListItemText primary={option.name} />
                </ListItemButton>
            </ListItem>
        )) : null;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: layoutContext.navBarWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: layoutContext.navBarWidth,
                        boxSizing: 'border-box',
                    },
                    zIndex: layoutContext.navBarZIndex
                }}
                anchor="left"
                variant="permanent"
            >
                <Toolbar />
                <Divider />
                <List>
                    {content}
                </List>
            </Drawer>
        </Box>
    )
}

export default NavBar;