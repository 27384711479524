import React from "react"
import BatchAttribute from "../../../../../../Models/BatchAttribute"
import TestType from "../../../../../../Models/TestType"
import { ContentWrapper, BlockWrapper } from "./styles"
import GenerateReagentTestTypeDisplay from '../../../../../../Utils/GenerateReagentTestTypeDisplay';
import GenerateExtractionTestTypeDisplay from '../../../../../../Utils/GenerateExtractionTestTypeDisplay';

interface BatchTestDetailsProps {
    attribute: BatchAttribute
}

export const BatchTestDetails = ({ attribute }: BatchTestDetailsProps) => {

    var customAttributeContent: JSX.Element = <></>;

    switch(attribute.type) {
        case TestType.PCR:
        case TestType.FRAGMENTANALYSIS:
        case TestType.PUNCHING:
            customAttributeContent = GenerateReagentTestTypeDisplay(attribute);
            break;
        case TestType.EXTRACTION:
            customAttributeContent = GenerateExtractionTestTypeDisplay(attribute);
            break;
    }

    return (
        <ContentWrapper>
            <BlockWrapper>
                <h3>
                    Batch test details
                </h3>
            </BlockWrapper>
            <BlockWrapper>
                {customAttributeContent}
            </BlockWrapper>
        </ContentWrapper>
    )
}