import Select, { SelectChangeEvent } from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem';
import { ReactNode } from 'react';

interface FieldProps {
  selectedValue: string;
  items: string[];
  onChange: Function;
  styles?:  React.CSSProperties;
}

function DropDownField(props: FieldProps) {

  var {selectedValue, items, onChange} = props;

  const handleSelectChangeEvent = (event: SelectChangeEvent<string>, child: ReactNode) => {
    onChange(event.target.value as string);
  }

  return (
    <Select
      labelId="mode-select-box-label"
      id="mode-select-box"
      value={selectedValue}
      label="Mode"
      onChange={handleSelectChangeEvent}
      >
      {items.map(item => <MenuItem value={item} key={`key-${item}`}>{item}</MenuItem>)}
    </Select>
  );

}

export default DropDownField;