import styled from "styled-components";

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

export const PageLayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%; 
  padding: 2rem 0;
`;