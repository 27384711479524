import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Specimen from '../Models/Specimen';

export interface SpecimenState {
    specimens: Specimen[]
}

const initialState: SpecimenState = {
    specimens: []
}

export const specimenSlice = createSlice({
  name: "specimens",
  initialState,
  reducers: {
    setSpecimens: (state, action: PayloadAction<Specimen[]>) => {
      state.specimens = action.payload
    },
    updateSpecimen: (state, action: PayloadAction<Specimen>) => {
      var index = state.specimens.findIndex(specimen => specimen.specimenId === action.payload.specimenId);
      if (index !== -1) state.specimens.splice(index, 1, action.payload);
    },
    removeSpecimen: (state, action: PayloadAction<Specimen>) => {
      var index = state.specimens.indexOf(action.payload);
      if (index !== -1) state.specimens.splice(index, 1);
    },
    removeSpecimens: (state, action: PayloadAction<Specimen[]>) => {
      var resultState = [...state.specimens];

      action.payload.forEach(specimen => {
        var index = resultState.indexOf(specimen);
        if (index !== -1) resultState.splice(index, 1);
      })

      state.specimens = resultState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSpecimens, removeSpecimen, removeSpecimens, updateSpecimen } = specimenSlice.actions

export default specimenSlice.reducer