import { CircularProgress, Snackbar, TextField } from '@mui/material';
import { Typography, Divider } from '@mui/material';
import React, { useRef, useState } from 'react';
import { GetAllSpecimensByCaseId, GetAllSpecimensByName } from '../../../../Graphql/Api';
import Specimen from '../../../../Models/Specimen';
import {ContentWrapper, SectionWrapper, SideBySideWrapper, ComponentWrapper, SpinnerWrapper, RightPadding, Subtitle } from './styles';
import MuiAlert from '@mui/material/Alert';
import ArchivedSpecimen from '../../../../Models/ArchivedSpecimen';
import SearchSpecimenTable from './Components/SearchSpecimenTable';

interface DisplaySpecimenData {
    specimens: Specimen[],
    archivedSpecimens: ArchivedSpecimen[]
}

interface SearchTabProps {
    onNavigateToAnyBatch:(batchName: string, isArchived: boolean) => void
}

const SearchTab = ({ onNavigateToAnyBatch }: SearchTabProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [displaySpecimens, setDisplaySpecimens] = useState<DisplaySpecimenData>({specimens: [], archivedSpecimens: []});

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastError, setToastError] = useState<string>("");

    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const firstNameRef = useRef<HTMLInputElement | null>(null);

    const handleCaseIdInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") return; 

        var target = event.target as HTMLInputElement;
        var caseId = target.value;

        if (caseId === "") return;

        setIsLoading(true);

        GetAllSpecimensByCaseId(caseId)
        .then((data: DisplaySpecimenData) => {
            setDisplaySpecimens(data);
        })
        .catch((error: Error) => {
            var message = "Error when getting specimen by Case Id";
            setToastError(message);
            console.error(message);
            console.error(error);
            setToastOpen(true);
        })

        setIsLoading(false);
    }

    const handleNameInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") return; 

        var lastName = lastNameRef.current?.value;
        var firstName = firstNameRef.current?.value;

        if (lastName === "") return;

        setIsLoading(true);

        GetAllSpecimensByName(lastName!, firstName)
        .then((data: DisplaySpecimenData) => {
            setDisplaySpecimens(data);
        })
        .catch((error: Error) => {
            var message = "Error when getting specimen by Case Id";
            setToastError(message);
            console.error(message);
            console.error(error);
            setToastOpen(true);
        })

        setIsLoading(false);
    }

    const handleToastClose = () => {
        setToastOpen(false);
        setToastError("");
    }
    
    return (
        <>
            {isLoading &&
                <SpinnerWrapper>
                    <CircularProgress />
                </SpinnerWrapper>
            }
            {!isLoading &&
            <ContentWrapper>
                <SectionWrapper>   
                    <Typography gutterBottom variant="h4" component="div">
                        Search
                    </Typography>
                    <Subtitle>Press Enter to search</Subtitle>
                </SectionWrapper>
                <Divider variant="middle" />
                <SectionWrapper>
                    <ComponentWrapper>
                        <TextField 
                            id="case-id-input"
                            variant="standard" 
                            onKeyUp={handleCaseIdInput}
                            label="Case ID"
                        />
                    </ComponentWrapper>
                </SectionWrapper>
                <Divider variant="middle" />
                <SectionWrapper>
                    <Subtitle>Last name is mandatory, first name is optional. The search will only match exactly.</Subtitle>
                    <SideBySideWrapper>
                        <ComponentWrapper>
                            <RightPadding>
                                <TextField 
                                    id="specimen-name-input"
                                    variant="standard" 
                                    label="Last name"
                                    inputRef={lastNameRef}
                                    onKeyUp={handleNameInput}
                                />
                            </RightPadding>
                            <TextField 
                                id="specimen-name-input"
                                variant="standard" 
                                label="First name"
                                inputRef={firstNameRef}
                                onKeyUp={handleNameInput}
                            />
                        </ComponentWrapper>
                    </SideBySideWrapper>
                </SectionWrapper>
            </ContentWrapper>}
            <Divider variant="middle" />
            <SearchSpecimenTable 
                activeSpecimens={displaySpecimens.specimens} 
                archivedSpecimens={displaySpecimens.archivedSpecimens}
                onNavigateToBatch={onNavigateToAnyBatch} 
            />
            <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleToastClose}>
                <MuiAlert onClose={handleToastClose} variant="filled" severity={toastError === "" ? "success" : "error"} sx={{ width: '100%' }}>
                    {toastError === "" ? "Success!" : toastError}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default SearchTab;