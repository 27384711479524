import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
`;

export const SectionWrapper = styled.div`
    display: flex;
    padding: 0.5rem;
`;

export const TestComponentsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const TestComponent = styled.div`
    display: flex;    
    width: 25%;
`;

export const AttributeComponent = styled.div`
    display: flex;    
    width: 100%;
`;

export const SpinnerWrapper = styled.div`
    display: flex;
    width: 100%;

    justify-content: center;
    padding: 5rem;
`;