import BatchReportData from '../Models/BatchReportData';

const SortBatchReportDataByWellNumbers = (array: BatchReportData[]) =>  {

    var result = array.sort((a,b) => {

        var letterA = a.wellNumber.slice(0,1);
        var numberA = Number(a.wellNumber.slice(1));

        var letterB = b.wellNumber.slice(0,1);
        var numberB = Number(b.wellNumber.slice(1));

        if (numberA < numberB) return -1;
        if (numberA > numberB) return 1;

        if (letterA < letterB) return -1;
        if (letterA > letterB) return 1;

        return 0;
    });
    
    return result;
}

export default SortBatchReportDataByWellNumbers;