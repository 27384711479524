import { useContext, useEffect, useState } from 'react';
import { redirect } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DropDownField from "../../Components/DropDown";

import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context/Auth/AuthContext';
import AuthContextType from '../../Models/AuthContextType';
import { AppModes } from '../../Models/Constants/AppModes';
import { AppModeContext } from '../../Context/AppMode/AppModeContext';
import AppModeContextType from '../../Models/AppModeContextType';

const Header = () => {
    var authContext = useContext(AuthContext) as AuthContextType;
    const modeContext = useContext(AppModeContext) as AppModeContextType;
    var navigate = useNavigate();

    var [userLoggedIn, setIsUserLoggedIn] = useState<boolean>(authContext.user !== null);

    useEffect(() => {
        setIsUserLoggedIn(authContext.user !== null);
    }, [authContext]);

    const handleLogoutUser = () => {
        authContext.logout()
        .then(() => {
            authContext.setUser(null);
            navigate("/login");
        })
        .catch(error => {
            console.error("Error logging out user - " + error.message);
            alert("Error logging out user - " + error.message);
        })
        
    };
    
    const handleLoginRedirect = () => {
        navigate("/login");
    }

    var username = userLoggedIn ? authContext.user?.getUsername() : "";

    var updateUIMode = async (uiMode: string) => {
        var mode : AppModes = uiMode as AppModes;
        modeContext.setAppMode(mode);
        navigate("/");
    }

    return (
        <Box sx={{ flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar >
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        LIMS
                    </Typography>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 3 }}>
                    <div style={{float: "left", margin: 15}}>Mode:</div>
                    <DropDownField  
                        styles={{float: "left",marginTop: 10}} 
                        items = {Object.values(AppModes)} 
                        onChange={(uiMode : string) => updateUIMode(uiMode)} 
                        selectedValue={modeContext.mode}
                    />
                    </Typography>
                    {userLoggedIn ?
                        <>
                            <Typography component="div" sx={{ padding: '0 0.5rem'}}>
                                {username}
                            </Typography>
                            <Button color="inherit" onClick={handleLogoutUser}>Logout</Button> 
                        </>:
                        <Button color="inherit" onClick={handleLoginRedirect}>Login</Button>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header;