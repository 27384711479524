import TestType from '../Models/TestType';
import BatchAttribute from '../Models/BatchAttribute';
import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import GenerateReagentTestTypeDisplay from './GenerateReagentTestTypeDisplay';
import GenerateExtractionTestTypeDisplay from './GenerateExtractionTestTypeDisplay';

const GenerateTestTableRow = (type: TestType, attribute?: BatchAttribute): JSX.Element => {

    var approverContent: JSX.Element = <></>;
    var dateCreatedContent: JSX.Element = <></>;
    var customAttributeContent: JSX.Element = <></>;

    if (attribute) {

        approverContent = <>{`Approver: ${attribute.approver}`}</>;

        var localDate = new Date(attribute.dateCreated);

        dateCreatedContent = <>{`Date created: ${localDate.toLocaleTimeString()} ${localDate.toLocaleDateString()}`}</>;

        if (attribute.customAttributes) {
            switch(type) {
                case TestType.PCR:
                case TestType.FRAGMENTANALYSIS:
                case TestType.PUNCHING:
                    customAttributeContent = GenerateReagentTestTypeDisplay(attribute);
                    break;
                case TestType.EXTRACTION:
                    customAttributeContent = GenerateExtractionTestTypeDisplay(attribute);
                    break;
            }
        }
    }

    return (
        <TableRow key={`test-row-${type}`}>
            <TableCell component="th" scope="row" sx={{fontWeight: 'bold'}}>{type}</TableCell>
            <TableCell>{approverContent}</TableCell>
            <TableCell>{dateCreatedContent}</TableCell>
            <TableCell style={{width: "50%"}}>{customAttributeContent}</TableCell>
        </TableRow>
    )
}

export default GenerateTestTableRow;