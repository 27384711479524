import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

const login = (username: string, password: string): Promise<CognitoUser> => {
    return Auth.signIn(username, password);
}

const logout = (): Promise<any> => {
    return Auth.signOut();
}

export {
    login,
    logout
};