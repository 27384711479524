export const PunchingReagents = [
    "Investigator STR Go Punch Buffer"
];

export const ExtractionReagents = [
    "Omega Extraction Kit Lot"
];

export const PCRReagents = [
    "Qiagen Fast Reaction Mix",
    "Qiagen Primer Mix",
    "Nuclease Free Water"
];

export const FragmentAnalysisReagents = [
    "POP 4 Polymer",
    "Cathode Buffer",
    "Anode Buffer",
    "Conditioning Reagent",
    "Septa Cathode Buffer",
    "Capillary Array",
    "DNA Size Standard"
];