export const getBatches = `
    query getBatches {
        getBatches {
            attributes {
                approver
                type
                dateCreated
                customAttributes
            }
            createdBy
            dateCreated
            name
            specimens {
                specimenId
                wellNumber
            }
            rackBarcode
            plateBarcode
            tube2DBarcodesFilename
        }
    }
`;

export const getArchivedSpecimens = `
    query getArchivedSpecimens {
        getArchivedSpecimens {
            specimenId
            lastName
            firstName
            dateReceived
            dateOfBirth
            comment
            caseId
            barcode
            archivedAt
            archivedBy
          }
    }
`;

export const getArchivedPatients = `
    query getArchivedPatients {
        getArchivedPatients {
            id
            sampleId
            firstName
            lastName
            dateOfBirth
            sex
            email
            mobilePhone
            homePhone
            sampleCollectionDate
            dateCreated
            journeyId
            orderId
            productCode
            sampleErrorNote
            archivedAt
            archivedBy
          }
    }
`;

export const getArchivedBatches = `
    query getArchivedBatches {
        getArchivedBatches {
            name
            specimens {
                specimenId
                wellNumber
            }
            dateCreated
            createdBy
            attributes {
                approver
                dateCreated
                customAttributes
                type
            }
            archivedAt
            archivedBy
            rackBarcode
            plateBarcode
            tube2DBarcodesFilename
        }
    }
`;

export const getSpecimens = `
    query getSpecimens {
        getSpecimens {
            barcode
            caseId
            comment
            dateOfBirth
            firstName
            lastName
            specimenId
            dateReceived
        }
    }
`;

export const getAllSpecimensByCaseId = `
    query getSpecimensByCaseId($caseId: String!) {
        getSpecimensByCaseId(caseId: $caseId) {
            barcode
            caseId
            comment
            dateOfBirth
            firstName
            lastName
            specimenId
            dateReceived
        }
        getArchivedSpecimensByCaseId(caseId: $caseId) {
            barcode
            caseId
            comment
            dateOfBirth
            firstName
            lastName
            specimenId
            dateReceived
            archivedBy
            archivedAt
            batchName
        }
    }
`;

export const getAllSpecimensByName = `
    query getSpecimensByName($lastName: String!, $firstName: String) {
        getSpecimensByName(lastName: $lastName, firstName: $firstName) {
            barcode
            caseId
            comment
            dateOfBirth
            firstName
            lastName
            specimenId
            dateReceived
        }
        getArchivedSpecimensByName(lastName: $lastName, firstName: $firstName) {
            barcode
            caseId
            comment
            dateOfBirth
            firstName
            lastName
            specimenId
            dateReceived
            archivedBy
            archivedAt
            batchName
        }
    }
`;

export const getRegisteredPatient = `
    query getRegisteredPatient($sampleId: String!) {
        getRegisteredPatient(sampleId: $sampleId) {
            sampleId
            journeyId
            orderId
            currentStatus
            orderType
            approvedAt
            patient {
                id
                gender
                firstName
                lastName
                dateOfBirth
                email
                pregnantStatus
            }
            product {
                displayName
                id
                productCode
            }
            registrationData {
                consentStatements {
                    type
                    version
                    timestamp
                }
                referringProvider {
                    providerId
                    firstName
                    lastName
                }
            }
            previousSample {
                sampleId
                workflowSteps {
                    name
                    isCompleted
                    hasError
                    errorCode
                }
            }
        }
    }
`;

export const getRegisteredPatients = `
    query getRegisteredPatients {
        getRegisteredPatients {
            sex
            sampleId
            sampleCollectionDate
            sampleType
            sampleErrorNote
            productCode
            productDisplayName
            orderId
            mobilePhone
            lastName
            journeyId
            id
            homePhone
            firstName
            email
            dateOfBirth
            createdBy
            dateCreated
            referringProviderId
            referringProviderFirstName
            referringProviderLastName
            orderType
            consentStatements {
                type
                version
                timestamp
            }
        }
    }
`;

export const getAllPatientsBySampleId = `
    query getPatientsBySampleId($sampleId: String!) {
        getPatientsBySampleId(sampleId: $sampleId) {
            sex
            sampleId
            sampleCollectionDate
            sampleType
            sampleErrorNote
            productCode
            productDisplayName
            orderId
            mobilePhone
            lastName
            journeyId
            id
            homePhone
            firstName
            email
            dateOfBirth
            createdBy
            dateCreated
            referringProviderId
            referringProviderFirstName
            referringProviderLastName
            orderType
            addToVCGSManifest
            addToGeneByGeneManifest
            previousSampleId
            consentStatements {
                type
                version
                timestamp
            }
            workflowSteps {
                name
                isCompleted
                hasError
                errorCode
            }
        }
        getArchivedPatientsBySampleId(sampleId: $sampleId) {
            sex
            sampleId
            sampleCollectionDate
            sampleType
            sampleErrorNote
            productCode
            productDisplayName
            orderId
            mobilePhone
            lastName
            journeyId
            id
            homePhone
            firstName
            email
            dateOfBirth
            createdBy
            dateCreated
            referringProviderId
            referringProviderFirstName
            referringProviderLastName
            orderType
            addToVCGSManifest
            addToGeneByGeneManifest
            previousSampleId
            consentStatements {
                type
                version
                timestamp
            }
            workflowSteps {
                name
                isCompleted
                hasError
                errorCode
            }
            archivedBy
            archivedAt
            batchName
        }
    }
`;

export const getAllPatientsByName = `
    query getPatientsByName($lastName: String!, $firstName: String) {
        getPatientsByName(lastName: $lastName, firstName: $firstName) {
            sex
            sampleId
            sampleCollectionDate
            sampleType
            sampleErrorNote
            productCode
            productDisplayName
            orderId
            mobilePhone
            lastName
            journeyId
            id
            homePhone
            firstName
            email
            dateOfBirth
            createdBy
            dateCreated
            referringProviderId
            referringProviderFirstName
            referringProviderLastName
            orderType
            addToVCGSManifest
            addToGeneByGeneManifest
            previousSampleId
            consentStatements {
                type
                version
                timestamp
            }
        }
        getArchivedPatientsByName(lastName: $lastName, firstName: $firstName) {
            sex
            sampleId
            sampleCollectionDate
            sampleType
            sampleErrorNote
            productCode
            productDisplayName
            orderId
            mobilePhone
            lastName
            journeyId
            id
            homePhone
            firstName
            email
            dateOfBirth
            createdBy
            dateCreated
            referringProviderId
            referringProviderFirstName
            referringProviderLastName
            orderType
            addToVCGSManifest
            addToGeneByGeneManifest
            previousSampleId
            consentStatements {
                type
                version
                timestamp
            }
            archivedBy
            archivedAt
            batchName	    
        }
    }
`;

export const specimenReceived = `
    query specimenReceived {
        specimenReceived {
            sampleId
            id
            firstName
            lastName
            sex
            dateOfBirth
            email
            homePhone
            mobilePhone
        }
    }
`;

export const specimenDnaExtracted = `
    query specimenDnaExtracted {
        specimenDnaExtracted {
            sampleId
            id
            firstName
            lastName
            sex
            dateOfBirth
            email
            homePhone
            mobilePhone
        }
    }
`;

export const getDNADilutions = `
    query getDNADilutions($batchName: String!) {
        getDNADilutions(batchName: $batchName) {
            sampleId
            concentration
        }
    }
`;
