import { API, Auth } from "aws-amplify";
import Batch from "../../Models/Batch";
import GraphqlBatch from '../../Models/Graphql/GraphqlBatch';
import Specimen from "../../Models/Specimen";
import { 
  getBatches, 
  getSpecimens, 
  getArchivedBatches, 
  getArchivedSpecimens,
  getArchivedPatients, 
  getAllSpecimensByCaseId, 
  getAllSpecimensByName, 
  getRegisteredPatient, 
  getRegisteredPatients,
  getAllPatientsBySampleId,
  getAllPatientsByName,
  getDNADilutions } from "../Queries";
import { upsertBatches, archiveBatch, archiveSpecimens, archivePatients, unarchivePatient, upsertSpecimens, updateCommentBySpecimenId, sendManifestToPartners, upsertPatient, specimenReceived, specimenDnaExtracted, specimenError, upsertTube2DBarcodes, upsertDNAConcentrations } from '../Mutations';
import UpsertBatchRequest from "../../Models/Graphql/UpsertBatchRequest";
import UpsertResponse from "../../Models/Graphql/UpsertResponse";
import BatchAttribute from "../../Models/BatchAttribute";
import TestType from "../../Models/TestType";
import ArchiveBatchRequest from '../../Models/Graphql/ArchiveBatchRequest';
import UpsertSpecimenRequest from '../../Models/Graphql/UpsertSpecimenRequest';
import ArchiveSpecimensRequest from '../../Models/Graphql/ArchiveSpecimensRequest';
import SendManifestToPartnersRequest from '../../Models/Graphql/SendManifestToPartnersRequest';
import Patient from '../../Models/Patient';
import ArchivedPatient from '../../Models/ArchivedPatient';
import UpsertPatientRequest from '../../Models/UpsertPatientRequest';
import ArchiveResponse from '../../Models/Graphql/ArchiveResponse';
import UnarchiveResponse from '../../Models/Graphql/UnarchiveResponse';
import ArchivedSpecimen from '../../Models/ArchivedSpecimen';
import ArchivePatientsRequest from '../../Models/Graphql/ArchivePatientsRequest';
import UnarchivePatientRequest from '../../Models/Graphql/UnarchivePatientRequest';
import ArchivedBatch from '../../Models/ArchivedBatch';
import SampleRegistration from "../../Models/SampleRegistration";
import SendManifestToPartnersResponse from '../../Models/Graphql/SendManifestToPartnersResponse';
import UpsertPatientResponse from '../../Models/Graphql/UpsertPatientResponse';
import SpecimenReceivedResponse from '../../Models/Graphql/SpecimenReceivedResponse'
import SpecimenDnaExtractedResponse from '../../Models/Graphql/SpecimenDnaExtractedResponse'
import SpecimenErrorResponse from '../../Models/Graphql/SpecimenErrorResponse'
import ResponseModel from '../../Models/Response';
import UpdateCommentBySpecimenIdRequest from '../../Models/UpdateCommentBySpecimenIdRequest';
import SpecimenReceivedRequest from '../../Models/SpecimenReceivedRequest';
import SpecimenDnaExtractedRequest from '../../Models/SpecimenDnaExtractedRequest';
import SpecimenErrorRequest from '../../Models/SpecimenErrorRequest';
import UpsertTube2DBarcodesRequest from "../../Models/Graphql/UpsertTube2DBarcodesRequest";
import UpsertDNAConcentrationsRequest from "../../Models/Graphql/UpsertDNAConcentrationsRequest";
import Tube2DBarcodes from "../../Models/Tube2DBarcodes";
import DNAConcentrations from "../../Models/DNAConcentrations";
import DNADilution from "../../Models/DNADilution";
import PatientInput from "../../Models/Graphql/PatientInput";

export const GetBatches = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getBatches,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getBatches: GraphqlBatch[]
    }
  };
  
  var batches = result.data.getBatches.map(graphqlBatch => {
    
    var attributes = graphqlBatch.attributes.map(att => {

      // The type value saved in dynamo db is the value of the key/value type TestType
      // We need to find the key of that
      var typeKeyIndex = Object.values(TestType).findIndex(x => x === att.type);
      var typeKey = Object.keys(TestType)[typeKeyIndex];

      return {
        approver: att.approver,
        type: TestType[typeKey as keyof typeof TestType],
        customAttributes: att.customAttributes,
        dateCreated: att.dateCreated
      } as BatchAttribute;
      
    });

    var batch: Batch = {
      name: graphqlBatch.name,
      rackBarcode: graphqlBatch.rackBarcode,
      plateBarcode: graphqlBatch.plateBarcode,
      tube2DBarcodesFilename: graphqlBatch.tube2DBarcodesFilename,
      dnaConcentrationsFilename: "",
      createdBy: graphqlBatch.createdBy,
      attributes,
      specimens: graphqlBatch.specimens,
      dateCreated: new Date(graphqlBatch.dateCreated)
    }

    return batch;
  })

  return batches;
};

export const GetSpecimens = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getSpecimens,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getSpecimens: Specimen[]
    }
  };

  return result.data.getSpecimens;
};

export const UpsertBatches = async (batch: Batch) => {
  const idToken = await getIdToken();

  var request: UpsertBatchRequest = {
    batches: [
      batch
    ]
  };

  var result = await API.graphql({
    query: upsertBatches,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      upsertBatches: UpsertResponse
    }
  };

  return result.data.upsertBatches;
};

export const UpsertSpecimens = async (specimens: Specimen[]) => {
  const idToken = await getIdToken();

  var request: UpsertSpecimenRequest = {
    specimens: specimens
  };

  var result = await API.graphql({
    query: upsertSpecimens,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      upsertSpecimens: UpsertResponse
    }
  };

  return result.data.upsertSpecimens;
};

export const ArchiveBatch = async (batch: Batch, archivedBy: string) => {
  const idToken = await getIdToken();

  var request: ArchiveBatchRequest = {
    batch,
    archivedBy
  };

  var result = await API.graphql({
    query: archiveBatch,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      archiveBatch: ArchiveResponse
    }
  };

  return result.data.archiveBatch;
};

export const ArchiveSpecimens = async (specimens: Specimen[], archivedBy: string, batchName: string) => {
  const idToken = await getIdToken();

  var request: ArchiveSpecimensRequest = {
    specimens,
    archivedBy,
    batchName
  };

  var result = await API.graphql({
    query: archiveSpecimens,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      archiveSpecimens: ArchiveResponse
    }
  };

  return result.data.archiveSpecimens;
};

export const ArchivePatients = async (patients: Patient[], archivedBy: string, batchName: string) => {
  const idToken = await getIdToken();

  var request: ArchivePatientsRequest = {
    patients,
    archivedBy,
    batchName
  };

  var result = await API.graphql({
    query: archivePatients,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      archivePatients: ArchiveResponse
    }
  };

  return result.data.archivePatients;
};

export const UnarchivePatient = async (sampleId: string) => {
  const idToken = await getIdToken();

  var request: UnarchivePatientRequest = {
    sampleId
  };

  var result = await API.graphql({
    query: unarchivePatient,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      unarchivePatient: UnarchiveResponse
    }
  };

  return result.data.unarchivePatient;
};

export const GetArchivedSpecimens = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getArchivedSpecimens,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getArchivedSpecimens: ArchivedSpecimen[]
    }
  };

  return result.data.getArchivedSpecimens;
};

export const GetArchivedPatients = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getArchivedPatients,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getArchivedPatients: ArchivedPatient[]
    }
  };

  return result.data.getArchivedPatients;
};

export const GetArchivedBatches = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getArchivedBatches,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getArchivedBatches: ArchivedBatch[]
    }
  };

  return result.data.getArchivedBatches;
};
    
export const GetAllSpecimensByCaseId = async (caseId: string) => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getAllSpecimensByCaseId,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      caseId: caseId
    }
  }) as {
    data: {
      getSpecimensByCaseId: Specimen[],
      getArchivedSpecimensByCaseId: ArchivedSpecimen[]
    }
  };

  return {
    specimens: result.data.getSpecimensByCaseId,
    archivedSpecimens: result.data.getArchivedSpecimensByCaseId
  };
};

export const GetAllSpecimensByName = async (lastName: string, firstName: string = "") => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getAllSpecimensByName,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      lastName: lastName,
      firstName: firstName
    }
  }) as {
    data: {
      getSpecimensByName: Specimen[],
      getArchivedSpecimensByName: ArchivedSpecimen[]
    }
  };

  return {
    specimens: result.data.getSpecimensByName,
    archivedSpecimens: result.data.getArchivedSpecimensByName
  }
};

export const UpdateCommentBySpecimenId = async (specimenId: string, comment: string) => {
  const idToken = await getIdToken();

  var request: UpdateCommentBySpecimenIdRequest = {
    specimenId: specimenId,
    comment: comment
  };

  var result = await API.graphql({
    query: updateCommentBySpecimenId,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request: request
    }
  }) as {
    data: {
      updateCommentBySpecimenId: ResponseModel
    }
  };

  return result.data.updateCommentBySpecimenId;
};

export const SendManifestToPartners = async (batch: Batch) => {
  const idToken = await getIdToken();

  var request: SendManifestToPartnersRequest = {
    batch
  };

  var result = await API.graphql({
    query: sendManifestToPartners,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      sendManifestToPartners: SendManifestToPartnersResponse
    }
  };

  return result.data.sendManifestToPartners;
};

export const UpsertPatient = async (patient: Patient) => { 
  
  const idToken = await getIdToken();

  // Map from Date types in Patient to string types for appsync
  var patientInput: PatientInput = {
    ...patient,
    dateOfBirth: patient.dateOfBirth.toISOString().split('T')[0],
    sampleCollectionDate: patient.sampleCollectionDate.toISOString().split('T')[0]
  };
  
  var request: UpsertPatientRequest = {
    patient: patientInput
  };

  var result = await API.graphql({
    query: upsertPatient,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      upsertPatient: UpsertPatientResponse
    }
  };

  return result.data.upsertPatient;
};

export const GetRegisteredPatient = async (sampleId: string) => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getRegisteredPatient,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      sampleId: sampleId
    }
  }) as {
    data: {
      getRegisteredPatient: SampleRegistration[]
    }
  };

  return result.data.getRegisteredPatient;
};

export const GetPatients = async () => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getRegisteredPatients,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  }) as {
    data: {
      getRegisteredPatients: Patient[]
    }
  };
  
  return result.data.getRegisteredPatients;
};

export const GetAllPatientsBySampleId = async (sampleId: string) => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getAllPatientsBySampleId,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      sampleId: sampleId
    }
  }) as {
    data: {
      getPatientsBySampleId: Patient[],
      getArchivedPatientsBySampleId: ArchivedPatient[]
    }
  };

  return {
    patients: result.data.getPatientsBySampleId,
    archivedPatients: result.data.getArchivedPatientsBySampleId
  };
};

export const GetAllPatientsByName = async (lastName: string, firstName: string) => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getAllPatientsByName,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      lastName: lastName,
      firstName: firstName
    }
  }) as {
    data: {
      getPatientsByName: Patient[],
      getArchivedPatientByName: ArchivedPatient[]
    }
  };

  return {
    patients: result.data.getPatientsByName,
    archivedPatients: result.data.getArchivedPatientByName
  };
};

export const SpecimenReceived = async (sampleId: string, journeyId: string, orderId: string, patientId: string, productCode: string) => {
  const idToken = await getIdToken();

  var request: SpecimenReceivedRequest = {
    sampleId: sampleId,
    journeyId: journeyId,
    orderId: orderId,
    patientId: patientId,
    productCode: productCode
  };

  var result = await API.graphql({
    query: specimenReceived,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      specimenReceived: SpecimenReceivedResponse
    }
  };

  return result.data.specimenReceived;
};

export const SpecimenDnaExtracted = async (sampleId: string, journeyId: string, orderId: string, patientId: string, productCode: string) => {
  const idToken = await getIdToken();

  var request: SpecimenDnaExtractedRequest = {
    sampleId: sampleId,
    journeyId: journeyId,
    orderId: orderId,
    patientId: patientId,
    productCode: productCode
  };

  var result = await API.graphql({
    query: specimenDnaExtracted,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      specimenDnaExtracted: SpecimenDnaExtractedResponse
    }
  };

  return result.data.specimenDnaExtracted;
};

export const SpecimenError = async (sampleId: string, journeyId: string, orderId: string, patientId: string, productCode: string, labNotes: string) => {
  const idToken = await getIdToken();

  var request: SpecimenErrorRequest = {
    sampleId: sampleId,
    journeyId: journeyId,
    orderId: orderId,
    patientId: patientId,
    productCode: productCode,
    labNotes: labNotes
  };

  var result = await API.graphql({
    query: specimenError,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      specimenError: SpecimenErrorResponse
    }
  };

  return result.data.specimenError;
};

export const UpsertTube2DBarcodes = async (barcodes: Tube2DBarcodes) => {
  const idToken = await getIdToken();

  var request: UpsertTube2DBarcodesRequest = {
    barcodes: [
      barcodes
    ]
  };

  var result = await API.graphql({
    query: upsertTube2DBarcodes,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      upsertTube2DBarcodes: UpsertResponse
    }
  };

  return result.data.upsertTube2DBarcodes;
};

export const UpsertDNAConcentrations = async (concentrations: DNAConcentrations) => {
  const idToken = await getIdToken();

  var request: UpsertDNAConcentrationsRequest = {
    concentrations: [
      concentrations
    ]
  };

  var result = await API.graphql({
    query: upsertDNAConcentrations,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      request
    }
  }) as {
    data: {
      upsertDNAConcentrations: UpsertResponse
    }
  };

  return result.data.upsertDNAConcentrations;
};

export const GetDNADilutions = async (batchName: string) => {
  const idToken = await getIdToken();

  var result = await API.graphql({
    query: getDNADilutions,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
    variables: {
      batchName: batchName
    }
  }) as {
    data: {
      getDNADilutions: DNADilution[]
    }
  };

  return result.data.getDNADilutions;
};

export const getIdToken = async () => {
  const currentSession = await Auth.currentSession();
  const token = currentSession.getIdToken();
  return token.getJwtToken();
};
