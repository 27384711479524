import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Batch from '../Models/Batch';

export interface BatchState {
  batches: Batch[]
}

const initialState: BatchState = {
  batches: []
}

export const batchSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    setBatches: (state, action: PayloadAction<Batch[]>) => {
      state.batches = action.payload
    },
    addBatch: (state, action: PayloadAction<Batch>) => {
      state.batches.push(action.payload)
    },
    removeBatch: (state, action: PayloadAction<Batch>) => {
      var index = state.batches.indexOf(action.payload);
      if (index !== -1) state.batches.splice(index, index);
    },
    updateBatch: (state, action: PayloadAction<Batch>) => {
      var index = state.batches.findIndex(batch => batch.name === action.payload.name);
      // Replace batch with payload
      if (index !== -1) state.batches.splice(index, 1, action.payload);
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBatches, addBatch, removeBatch, updateBatch } = batchSlice.actions

export default batchSlice.reducer