import { useContext } from "react";
import { TestComponentsWrapper, TestComponent, AttributeComponent } from "../../styles";
import { Typography, FormControl, Button } from "@mui/material";
import ApproverComponentProps from "../ApproverComponentProps";
import BatchAttribute from "../../../../Models/BatchAttribute";
import { AuthContext } from "../../../../Context/Auth/AuthContext";
import AuthContextType from "../../../../Models/AuthContextType";

const ApproverOnlyTestComponent = ({onSubmit, type, name}: ApproverComponentProps) => {
    var authContext = useContext(AuthContext) as AuthContextType;

    const handleSubmit = () => {

        var attribute: BatchAttribute = {
            type: type,
            approver: authContext.user!.getUsername(),
            dateCreated: new Date()
        };

        onSubmit(attribute);
    };

    return (
        <TestComponentsWrapper>
            <TestComponent>
                <Typography gutterBottom variant="h6" component="div">
                    {name}
                </Typography>
            </TestComponent>
            <TestComponent>
                <FormControl>
                    <Button onClick={handleSubmit} variant="contained">
                        SUBMIT
                    </Button>
                </FormControl>
            </TestComponent>
            <AttributeComponent >
                {/* For layout consistency */}
            </AttributeComponent>
        </TestComponentsWrapper>
    )
}

export default ApproverOnlyTestComponent;