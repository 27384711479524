import { createContext } from 'react';
import LayoutContextType from '../../Models/LayoutContextType';

export const LayoutContext = createContext<LayoutContextType | null>(null);

export const LayoutContextProvider = (props: any) => {

    const defaultNavbarWidth = 220;
    const defaultNavBarZIndex = -1;
    const defaultPageContentZIndex = -10;

    return (
        <LayoutContext.Provider value={{
            navBarWidth: defaultNavbarWidth,
            navBarZIndex: defaultNavBarZIndex,
            pageContentZIndex: defaultPageContentZIndex
        }} >
            {props.children}
        </LayoutContext.Provider>
    );
}
