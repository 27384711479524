import AppRouter from './Router/AppRouter';
import { AuthContextProvider } from './Context/Auth/AuthContext';
import { LayoutContextProvider } from './Context/Layout/LayoutContext';
import { AppModeContextProvider } from './Context/AppMode/AppModeContext';
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthContextProvider >
            <LayoutContextProvider>
              <AppModeContextProvider>
                <AppRouter />
              </AppModeContextProvider>
            </LayoutContextProvider>
          </AuthContextProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
