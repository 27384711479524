import React from 'react';
import { Checkbox, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ArchiveIcon from '@mui/icons-material/Archive';
import { StatusCellWrapper, StatusIconActiveWrapper, StatusIconArchivedWrapper, StatusTextWrapper, Heading, BlockWrapper } from './styles';
import Patient from '../../../../../../Models/Patient';

interface PatientDetailsProps {
    status: "outstanding" | "batched" | "archived", 
    patient: Patient,
    onPatientVCGSToggle: (patient: Patient) => void,
    onPatientGeneByGeneToggle: (patient: Patient) => void,
    onSavePatientToggles: (patient: Patient) => void,
    onUnarchivePatient: (sampleId: string) => void,
}

export const PatientDetails = ({patient, status, onPatientVCGSToggle, onPatientGeneByGeneToggle, onSavePatientToggles, onUnarchivePatient}: PatientDetailsProps) => {
    var isActive = status !== "archived";
    var isArchived = status === "archived";

    const handleVCGSCheckboxClick = () => {
        onPatientVCGSToggle(patient);
    }

    const handleGeneByGeneCheckboxClick = () => {
        onPatientGeneByGeneToggle(patient);
    }

    const handleSavePatientTogglesClick = () => {
        onSavePatientToggles(patient);
    }

    const handleUnarchivePatientClick = () => {
        onUnarchivePatient(patient.sampleId);
    }

    return (
        <React.Fragment>
            <BlockWrapper>
                <Heading>Sample status</Heading>
                <StatusCellWrapper>
                    {isActive ? <StatusIconActiveWrapper>
                        <CheckIcon color="inherit" /> : <ArchiveIcon color="inherit" />
                    </StatusIconActiveWrapper> :
                    <StatusIconArchivedWrapper>
                        <CheckIcon color="inherit" /> : <ArchiveIcon color="inherit" />
                    </StatusIconArchivedWrapper>}
                    <StatusTextWrapper>
                        {status.toUpperCase()}
                    </StatusTextWrapper>
                </StatusCellWrapper>
            </BlockWrapper>
            <BlockWrapper>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Heading>NAME</Heading>
                                </TableCell>
                                <TableCell>{`${patient.firstName.toUpperCase()} ${patient.lastName.toUpperCase()}`}</TableCell>
                                <TableCell>
                                    <Heading>SAMPLE ID</Heading>
                                </TableCell>
                                <TableCell>{patient.sampleId}</TableCell>
                                <TableCell>
                                    <Heading>PRODUCT CODE</Heading>
                                </TableCell>
                                <TableCell>{patient.productCode}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Heading>JOURNEY ID</Heading>
                                </TableCell>
                                <TableCell>{patient.journeyId}</TableCell>
                                <TableCell>
                                    <Heading>ORDER ID</Heading>
                                </TableCell>
                                <TableCell>{patient.orderId}</TableCell>
                                <TableCell>
                                    <Heading>LUMI ID</Heading>
                                </TableCell>
                                <TableCell>{patient.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Heading>SEX</Heading>
                                </TableCell>
                                <TableCell>{patient.sex}</TableCell>
                                <TableCell>
                                    <Heading>EMAIL</Heading>
                                </TableCell>
                                <TableCell>{patient.email}</TableCell>
                                <TableCell>
                                    <Heading>DATE OF BIRTH</Heading>
                                </TableCell>
                                <TableCell>{patient.dateOfBirth.toLocaleDateString('en-AU')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Heading>DATE RECEIVED</Heading>
                                </TableCell>
                                <TableCell>{patient.dateCreated.toLocaleDateString('en-AU')}</TableCell>
                                <TableCell>
                                    <Heading>ORDER TYPE</Heading>
                                </TableCell>
                                <TableCell>{patient.orderType}</TableCell>
                                <TableCell>
                                    <Heading>COLLECTION DATE</Heading>
                                </TableCell>
                                <TableCell>{patient.sampleCollectionDate.toLocaleDateString('en-AU')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {isActive ?
                                <TableCell>
                                    <Heading>Add to VCGS manifest</Heading>
                                </TableCell> : <TableCell></TableCell>}
                                {isActive ?
                                <TableCell><Checkbox checked={patient.addToVCGSManifest} onClick={handleVCGSCheckboxClick}/></TableCell> : <TableCell></TableCell>}
                                {isActive ?
                                <TableCell>
                                    <Heading>Add to GeneByGene manifest</Heading>
                                </TableCell> : <TableCell></TableCell>}
                                {isActive ?
                                <TableCell><Checkbox checked={patient.addToGeneByGeneManifest} onClick={handleGeneByGeneCheckboxClick}/></TableCell> : <TableCell></TableCell>}
                                <TableCell>
                                    <Heading> </Heading>
                                </TableCell>
                                {isArchived ?
                                <TableCell><Button color="success" variant="contained" onClick={handleUnarchivePatientClick}>Unarchive</Button></TableCell> :
                                <TableCell><Button color="success" variant="contained" onClick={handleSavePatientTogglesClick}>Save</Button></TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </BlockWrapper>
        </React.Fragment>
    )
}

