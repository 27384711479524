import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    width: 100%;

    justify-content: space-between;

    flex-direction: row;
`;

export const ThirdWrapper = styled.div`
    display: flex;
    width: 33%;

    align-items: center;

    font-size: 12px;
`;

