import { TableCell } from '@mui/material';
import styled from 'styled-components';

export const TableWrapper = styled.div`
    padding: 0.5rem;
`;

export const StatusCellWrapper = styled.div`
    display: flex;
    width: 100%;

    flex-direction: row;
    align-items: stretch;
`;

export const StatusIconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StatusTextWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 0.5rem;
`;

export const BoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;

export const CollapseCell = styled(TableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

export const Heading = styled.div`
    font-weight: bold
`;