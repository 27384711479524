import { NavBarOptions } from "../Models/Constants/NavBarOptions";
import { CarrierScreeningPanelConfig } from "../Models/Constants/PanelConfigs";
import { AppModeConfig, BatchConfig } from "./AppModeConfig";
import { CreateBatchScreenFields } from '../Models/AppConfig/CreateBatchScreenFields'; 
import { SendManifestToPartners, SpecimenDnaExtracted } from "../Graphql/Api";
import { CompleteBatchResponse } from "../Models/AppConfig/CompleteBatchResponse";
import Batch from "../Models/Batch";
import Patient from "../Models/Patient";
import Specimen from "../Models/Specimen";
import TestType from "../Models/TestType";
import { AppModes } from "../Models/Constants/AppModes";
import { BatchTabs } from "../Models/BatchTabs";

const completeBatch = async (batch: Batch, specimens: Specimen[], patients: Patient[]) => {
    
    console.log("Beginning complete batch for Carrier Screening mode");

    var result: CompleteBatchResponse = {
        isSuccess: false,
        messages: []
    };

    result = await handleSendManifestToPartners(batch, result);
    if (!result.isSuccess) return result;

    result = await handleDNAExtracted(batch, result, patients);
    if (!result.isSuccess) return result;

    console.log("Successfully completed batch for Carrier Screening mode");

    return result;
}

const handleSendManifestToPartners = async (batch: Batch, result: CompleteBatchResponse) => {

    try {
        let response = await SendManifestToPartners(batch);
        result.isSuccess = response.isSuccess;

        if (!result.isSuccess) {
            var message = "Error sending manifest files to partners";
            console.error(message);
            alert(message);
        }

    } catch (error) {
        result.isSuccess = false;
        result.messages.push(JSON.stringify(error));
    }

    return result;
}

const handleDNAExtracted = async (batch: Batch, result: CompleteBatchResponse, patients: Patient[]) => {

    var batchSpecimenIds = batch.specimens.map(specimen => specimen.specimenId);
    
    // todo: It is possible to return more than 1 patient per sample ID
    // put a check here that there is only 1 response
    // Though this is only possible if data has been manipulated manually in the database
    var batchRegisteredPatients = patients.filter(patient => batchSpecimenIds!.includes(patient.sampleId));

    // Performing an async operation over a list, we must await the result of all of the requests
    for await (const patient of batchRegisteredPatients) {
        // Skip the samples that have an error note and have been reported as error.
        if (!patient.sampleErrorNote) {
            try {
                let response = await SpecimenDnaExtracted(
                    patient.sampleId,
                    patient.journeyId,
                    patient.orderId,
                    patient.id,
                    patient.productCode);
                    
                    result.isSuccess = response.success;
    
                    if (!result.isSuccess) {
                        let message = "Error sending DNA extraction status to partners";
                        console.error(message);
                        alert(message);
                    }
            } catch (error) {
                result.isSuccess = false;
                result.messages.push(JSON.stringify(error));
            }
        }
    };

    return result;
};

const config: BatchConfig = {
    fetchData: () => {},
    archiveData: () => {},
    completeBatch: completeBatch,
    defaultPanelConfig: CarrierScreeningPanelConfig,
    requiredFields: [
        CreateBatchScreenFields.PLATE,
        CreateBatchScreenFields.RACK,
        CreateBatchScreenFields.TUBE2DBARCODE,
        CreateBatchScreenFields.DNACONCENTRATION
    ],
    testTypes: [
        TestType.EXTRACTION
    ],
    batchTabs: [
        BatchTabs.VIEW,
        BatchTabs.CREATE,
        BatchTabs.ARCHIVED,
        BatchTabs.CARRIEROUSTANDING,
        BatchTabs.CARRIERSEARCH
    ]
};

export const CarrierScreeningModeConfig: AppModeConfig = {
    NavBarOptions: [
        NavBarOptions.specimenReception,
        NavBarOptions.batches,
        NavBarOptions.testing
    ],
    BatchConfig: config,
    name: AppModes.CARRIERSCREENING
}
