import styled from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BlockWrapper = styled.div`
    display: flex;
    padding: 1rem;
`;